import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ClassesStore from "../../../../../../stores/ClassesStore";
import CurriculumStore from "../../../../../../stores/CurriculumStore";
import { Button, Spinner, Select, MultiSelect, ClassOption } from "../../../../../-common";
import "./ReassignStudentsModal.scss";

const sortClassOptions = (a, b) => {
  const aCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[a?.courseId]?.order;
  const bCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[b?.courseId]?.order;
  if (aCurriculumOrder !== bCurriculumOrder) {
    return aCurriculumOrder > bCurriculumOrder ? 1 : -1;
  }

  const aCourseOrder = CurriculumStore?.coursesById?.[a?.courseId]?.order;
  const bCourseOrder = CurriculumStore?.coursesById?.[b?.courseId]?.order;
  if (aCourseOrder !== bCourseOrder) {
    return aCourseOrder > bCourseOrder ? 1 : -1;
  }

  if (a?.dayOfWeek !== b?.dayOfWeek) {
    return a?.dayOfWeek > b?.dayOfWeek ? 1 : -1;
  }

  return a?.name > b?.name ? 1 : -1;
};

const filterClassOptions = ({ value }, searchTerm) => value?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase());
const getClassOptionLabel = ({ value }) => `${value.name} (${value?.sessionTime})`;
const filterStudentOptions = ({ value }, searchTerm) => {
  const fullName = `${value?.firstName || ""} ${value?.lastName || ""}`?.trim();
  return fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase());
};
const getStudentOptionLabel = ({ value }) => {
  if (value?.id === "Select All") return "Select All";
  return `${value?.firstName} ${value?.lastName || ""}`;
};

function ReassignStudentsModal({ class: currentClass, parent, students, materials, close }) {
  const [loading, setLoading] = useState(false);

  const [selectedClass, setSelectedClass] = useState();

  const [studentsToReassign, setStudentsToReassign] = useState([]);
  const updateStudentsToReassign = updatedStudents => {
    if (updatedStudents?.find(us => us?.id === "Select All")) {
      setStudentsToReassign(students);
    } else {
      setStudentsToReassign(updatedStudents);
    }
  };
  useEffect(() => {
    if (students?.length === 1) setStudentsToReassign(students);
  }, [students]);

  const relevantClasses = ClassesStore?.currentAndUpcomingClasses
    ?.filter(cu => cu.id !== currentClass?.id && cu?.courseId === currentClass?.courseId)
    ?.sort(sortClassOptions)
    ?.map((c, i) => ({ ...c, isFirst: i === 0 }));

  const processReassignment = async () => {
    setLoading(true);
    const preppedItemsToTransfer = materials?.map(({ userId, orderId, id }) => ({ userId, orderId, id }));
    await ClassesStore.reassignStudents({
      oldClassId: currentClass?.id,
      newClassId: selectedClass?.id,
      parentId: parent?.id,
      studentIds: studentsToReassign?.map(s => s?.id),
      itemsToTransfer: preppedItemsToTransfer
    });
    close();
    setLoading(false);
  };

  let studentOptions = [];
  if (students?.length) studentOptions = students;
  if (studentsToReassign?.length !== students?.length) {
    studentOptions = [{ id: "Select All" }]?.concat(studentOptions);
  }

  const studentsSection =
    students?.length !== 1 ? (
      <MultiSelect
        placeholder="Select Students to Reassign"
        options={studentOptions}
        value={studentsToReassign}
        onChange={updateStudentsToReassign}
        filterOption={filterStudentOptions}
        formatOptionLabel={getStudentOptionLabel}
        isDisabled={students?.length === 1}
      />
    ) : (
      <div className="student-name">
        {students?.[0]?.firstName} {students?.[0]?.lastName}
      </div>
    );

  const submitDisabled = !studentsToReassign?.length || !selectedClass;
  const sendButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const sendButtonContent = loading ? <Spinner /> : "Reassign";

  return (
    <div className="reassign-student-modal">
      <div className="content">
        <div className="title">Reassign Student</div>
        <div className="section students-to-drop">
          <div className="section-header">Student{students?.length !== 1 ? "s" : ""} to Reassign</div>
          {studentsSection}
        </div>
        <div className="section">
          <div className="section-header">New Class</div>
          <Select
            options={relevantClasses}
            value={selectedClass}
            onChange={setSelectedClass}
            components={{ Option: ClassOption }}
            filterOption={filterClassOptions}
            formatOptionLabel={getClassOptionLabel}
            maxMenuHeight={220}
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            style={sendButtonStyles}
            action={processReassignment}
            disabled={submitDisabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ReassignStudentsModal);
