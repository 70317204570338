import React from "react";
import CheckIcon from "../icons/CheckIcon";
import "./Checkbox.scss";

const Checkbox = ({ value, selected, onChange }) => {
  const toggle = () => onChange([!selected, value]);

  const selectedIcon = selected ? <CheckIcon /> : null;

  return (
    <div className="lpm-checkbox" onClick={toggle}>
      <div className={`lpm-checkbox-box${selected ? " selected" : ""}`}>{selectedIcon}</div>
      <div className="lpm-checkbox-label">{value}</div>
    </div>
  );
};

export default Checkbox;
