import React, { useState } from "react";
import { observer } from "mobx-react";
import { getDataURLFromFile } from "../../../utils";
import UserStore from "../../../stores/UserStore";
import { Spinner, ImageFileSelect, Button } from "../../-common";
import "./SwitchProfilePictureModal.scss";

function SwitchProfilePictureModal({ close }) {
  const [updating, setUpdating] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const updateEnabled = !!file;
  const update = async () => {
    if (updateEnabled) {
      setUpdating(true);
      try {
        await UserStore.updateProfilePicture(file);
        close();
      } catch (err) {
        setUpdating(false);
      }
    }
  };

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update";

  return (
    <div className="switch-profile-picture-modal">
      <div className="content">
        <div className="title">Switch Profile Picture</div>
        <div className="main-content-wrapper">
          <div className="artwork-select-container">
            <ImageFileSelect {...{ filePreviewURL, updateFile }} />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={update} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(SwitchProfilePictureModal);
