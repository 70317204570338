import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { Spinner } from "../../-common";
import UserStore from "../../../stores/UserStore";
import "./ChildDetails.scss";

function ChildDetails({ lpmId }) {
  const { loading, children } = UserStore || {};

  const child = children?.find(c => c?.id === lpmId);
  useEffect(() => {
    if (children?.length && !child) navigate("/children");
  }, [children, child]);

  if (loading) {
    return (
      <div className="child-details child-details-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  return (
    <div className="child-details">
      <div className="title-row">
        <div className="title">Child Details</div>
      </div>
    </div>
  );
}

export default observer(ChildDetails);
