import { useEffect } from "react";
import { useLocation } from "@reach/router";
import chunk from "lodash.chunk";
import { EVENT_NAMES } from "../constants";
import { initAmplitude, trackAmplitudeEvent } from "../services/AmplitudeService";

export default function useAmplitudeAnalytics() {
  const location = useLocation();

  useEffect(() => {
    initAmplitude();
  }, []);

  useEffect(() => {
    const { pathname, search } = location;
    const pathParts = pathname?.split("/");

    const tab = search?.split("tab=")?.[1];
    const additionalDetails = pathParts?.length > 2 ? chunk(pathParts.slice(1), 2) : [];

    const pageProperties = {
      page: pathParts[1],
      ...(additionalDetails?.length ? { ...Object.fromEntries(additionalDetails) } : {}),
      ...(tab ? { tab } : {})
    };
    trackAmplitudeEvent(EVENT_NAMES.VIEWED_PAGE, pageProperties);
  }, [location]);
}
