import { useState } from "react";
import { createPortal } from "react-dom";
import { FullScreenModal } from "../components/-common";
import usePortal from "./usePortal";

export default function useModal(ModalComponent, props, { portalElementId, big } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const Modal = () => (
    <FullScreenModal big={big} isOpen={true} close={closeModal}>
      <ModalComponent {...{ close: closeModal, ...props }} />
    </FullScreenModal>
  );

  const portalTarget = usePortal(portalElementId || "modal-container");
  const contents = isOpen ? <Modal {...props} /> : null;
  const ModalContainer = () => createPortal(contents, portalTarget);

  return { isOpen, openModal, closeModal, Modal: ModalContainer };
}
