import React from "react";
import { observer } from "mobx-react";
import { Router, Redirect, LocationProvider } from "@reach/router";
import { toast } from "react-toastify";
import { FiX } from "react-icons/fi";
import useAmplitudeAnalytics from "../hooks/useAmplitudeAnalytics";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";
import { Spinner } from "../components/-common";
import Sidebar from "../components/Sidebar";
import AuthRoutes from "../components/@Auth";
import Profile from "../components/@Profile";
import Files from "../components/@Files";
import Classes from "../components/@Classes";
import Children from "../components/@Children";
import Enrollments from "../components/@Enrollments/Enrollments";
import Teachers from "../components/@Teachers";
import OrderMaterials from "../components/@OrderMaterials";
import ThankYou from "../components/@ThankYou";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const ToastClose = ({ closeToast }) => (
  <div className="toast-close">
    <FiX size={20} onClick={closeToast} />
  </div>
);

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  closeButton: <ToastClose />,
  className: "lpm-toast"
});

function Main() {
  useAmplitudeAnalytics();
  const { teacher: isTeacher, parent: isParent } = UserStore?.user || {};
  const teacherRoutes = isTeacher ? (
    <>
      <Files path="/files/*" />
      <Classes path="/classes/*" />
    </>
  ) : null;
  const parentRoutes = isParent ? (
    <>
      <Children path="/children/*" />
      <Enrollments path="/enrollments" />
      <Teachers path="/teachers/*" />
    </>
  ) : null;
  return (
    <div className="main-content">
      <Sidebar />
      <Router>
        <Profile path="/profile" />
        {teacherRoutes}
        {parentRoutes}
        <OrderMaterials path="/materials" />
        <ThankYou path="/thankyou" />
        <Redirect from="*" to="/profile" noThrow />
      </Router>
    </div>
  );
}

const App = observer(() => {
  if (!AuthStore.authDetermined || (AuthStore.authenticated && !UserStore?.user?.id && UserStore.loading)) {
    return (
      <div className="root loading">
        <Spinner alt={true} />
      </div>
    );
  }

  if (AuthStore.authenticated) return <Main />;
  return <AuthRoutes />;
});

function ProvidersWrappedApp() {
  return (
    <LocationProvider>
      <App />
    </LocationProvider>
  );
}

export default ProvidersWrappedApp;
