import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { Link, useLocation, navigate } from "@reach/router";
import {
  FiSettings,
  FiUser,
  FiUsers,
  FiBookOpen,
  FiStar,
  FiBox,
  FiMusic,
  FiFolder,
  FiExternalLink
} from "react-icons/fi";
import logoBanner from "../../assets/logo-banner.png";
import useModal from "../../hooks/useModal";
import AddFeedbackModalBody from "./AddFeedbackModal";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import UserStore from "../../stores/UserStore";
import AuthStore from "../../stores/AuthStore";
import "./Sidebar.scss";

const linkColors = [
  "var(--red)",
  "var(--orange)",
  "var(--yellow)",
  "var(--green)",
  "var(--blue)",
  "var(--purple)",
  "var(--medium-gray)"
];
const linkIcons = {
  profile: FiUser,
  children: FiUsers,
  enrollments: FiBookOpen,
  teachers: FiStar,
  files: FiFolder,
  classes: FiMusic,
  materials: FiBox,
  webstore: FiExternalLink
};
const linkTitles = {
  profile: "My Profile",
  children: "My Children",
  enrollments: "My Enrollments",
  teachers: "My Teachers",
  files: "Uploads & Certs",
  classes: "Classes I Teach",
  materials: "Order Materials",
  webstore: "Web Store"
};

const SidebarLink = ({ link, currentPath, path, icon: Icon, title, color, href }) => {
  const active = !!currentPath.match(new RegExp("^" + path));
  const linkColorStyles = {
    color: active ? "var(--white)" : color,
    backgroundColor: active ? color : null
  };
  const iconColorStyles = { stroke: active ? "var(--white)" : color };

  if (link === "webstore") {
    return (
      <a
        href="https://www.new.letsplaymusicsite.com/shop"
        className="sidebar-link-wrapper"
        rel="noreferrer"
        target="_blank"
      >
        <div className="sidebar-link" style={linkColorStyles}>
          <Icon className="link-icon" style={iconColorStyles} />
          <span>{title}</span>
        </div>
      </a>
    );
  }

  return (
    <Link className="sidebar-link-wrapper" to={path}>
      <div className="sidebar-link" style={linkColorStyles}>
        <Icon className="link-icon" style={iconColorStyles} />
        <span>{title}</span>
      </div>
    </Link>
  );
};

const SettingsMenuContent = observer(({ close, openFeedback }) => {
  const { firstName, lastName, email, profilePicture, teacher: isTeacher } = UserStore?.user || {};

  const [pictureError, setPictureError] = useState(false);
  const markPictureError = () => setPictureError(true);

  const goTo = route => e => {
    navigate(route);
    close(e);
  };

  const goToExternal = href => e => {
    window.open(href, "_blank");
    close(e);
  };

  const showFeedbackModal = e => {
    openFeedback();
    close(e);
  };

  const signOut = () => AuthStore?.signOut();

  const pictureComponent = pictureError ? (
    <div className="picture placeholder">
      <FiUser size={20} />
    </div>
  ) : (
    <img className="picture" alt="My profile." src={profilePicture} onError={markPictureError} />
  );

  const forumLink = isTeacher ? (
    <div className="settings-menu-item action" onClick={goToExternal("https://infohouse.letsplaymusicsite.com")}>
      INFO House
    </div>
  ) : null;

  return (
    <div className="settings-menu-content">
      <div className="settings-menu-section">
        <div className="settings-menu-header">Account</div>
        <div className="picture-row">
          {pictureComponent}
          <div className="primary-info">
            <div className="name">
              {firstName} {lastName}
            </div>
            <div className="email">{email}</div>
          </div>
        </div>
        <div className="settings-menu-item action" onClick={goTo("/profile")}>
          My Profile
        </div>
        <div className="settings-menu-item action" onClick={signOut}>
          Sign Out
        </div>
      </div>
      <div className="settings-menu-section">
        <div className="settings-menu-header">Quick Links</div>
        {forumLink}
        <div className="settings-menu-item action" onClick={goToExternal("https://playmusic.customerhub.net/")}>
          Digital Downloads (Customer Hub)
        </div>
        <div className="settings-menu-item action" onClick={showFeedbackModal}>
          Need Help?
        </div>
      </div>
    </div>
  );
});

const Links = ({ isTeacher, isParent, pathname }) => {
  const profileLink = ["profile"];
  const parentLinks = isParent ? ["children", "enrollments", "teachers"] : [];
  const teacherLinks = isTeacher ? ["files", "classes"] : [];
  const commonLinks = ["materials", "webstore"];
  const allLinks = profileLink.concat(teacherLinks).concat(parentLinks).concat(commonLinks);
  return allLinks
    .map((link, i) => ({
      link,
      path: "/" + link,
      icon: linkIcons[link],
      title: linkTitles[link],
      color: linkColors[i]
    }))
    .map(props => <SidebarLink {...props} currentPath={pathname} key={props.title} />);
};

function Sidebar() {
  const { pathname } = useLocation();

  const { teacher: isTeacher, parent: isParent } = UserStore?.user || {};

  const [settingsMenuActive, setSettingsMenuActive] = useState(false);
  const openSettingsMenu = () => setSettingsMenuActive(true);
  const closeSettingsMenu = e => {
    e.stopPropagation();
    setSettingsMenuActive(false);
  };
  const settingsButtonRef = useRef();
  useOnClickOutside(settingsButtonRef, closeSettingsMenu);

  const { Modal: AddFeedbackModal, openModal: openAddFeedbackModal } = useModal(
    AddFeedbackModalBody,
    {},
    { portalElementId: "badd-feedback-modal" }
  );

  const settingsMenuContent = settingsMenuActive ? (
    <SettingsMenuContent close={closeSettingsMenu} openFeedback={openAddFeedbackModal} />
  ) : null;

  return (
    <div className="sidebar">
      <div className="logo-section">
        <img src={logoBanner} alt="LPM Logo" className="logo-banner" />
      </div>
      <div className="links-section">
        <Links {...{ isTeacher, isParent, pathname }} />
      </div>
      <div className="flexer" />
      <div
        ref={settingsButtonRef}
        className={`settings-button${settingsMenuActive ? " active" : ""}`}
        onClick={openSettingsMenu}
      >
        <FiSettings className="icon" />
        {settingsMenuContent}
      </div>
      <AddFeedbackModal />
    </div>
  );
}

export default observer(Sidebar);
