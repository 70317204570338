import React, { useState } from "react";
import { observer } from "mobx-react";
import uniqBy from "lodash.uniqby";
import { FiUser } from "react-icons/fi";
import { ENV } from "../../../config";
import "./TeacherRow.scss";

const MAX_COURSE_LOGOS = 4;

const TeacherRow = observer(({ id, profilePicture, gender, firstName, lastName, classes }) => {
  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);
  const profilePictureComponent = profilePictureError ? (
    <div className={`profile-picture placeholder${gender ? " " + gender : ""}`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img className="profile-picture" src={profilePicture} alt="Teacher profile" onError={markProfilePictureError} />
  );

  const currentUniqueCourses = uniqBy(classes, "courseId")?.sort((a, b) => {
    const aSortOrder = Number("" + a?.course?.curriculum?.order * 100 + a?.course?.order * 100);
    const bSortOrder = Number("" + b?.course?.curriculum?.order * 100 + b?.course?.order * 100);
    return aSortOrder - bSortOrder;
  });
  const currentCourseLogos = currentUniqueCourses
    ?.slice(0, MAX_COURSE_LOGOS)
    ?.map(cl => (
      <img
        className="currently-teaching-course-logo"
        src={cl?.course?.courseLogo}
        alt="Logo for this course"
        key={cl?.course?.id}
      />
    ))
    ?.concat(
      currentUniqueCourses?.length > MAX_COURSE_LOGOS ? (
        <div className="courses-overflow" key="overflow">
          + {currentUniqueCourses.length - MAX_COURSE_LOGOS} more
        </div>
      ) : null
    )
    ?.filter(Boolean);

  const enrollmentPageLink = `https://enrollment${ENV === "dev" ? ".dev" : ""}.letsplaymusicsite.com/teachers/${id}`;

  return (
    <tr>
      <td>
        <div className="cell-contents flex-center">
          {profilePictureComponent}
          <div>
            <div>
              {firstName} {lastName}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents currently-teaching">{currentCourseLogos}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action">
            <a href={enrollmentPageLink} rel="noreferrer" target="_blank">
              See Upcoming Classes
            </a>
          </div>
        </div>
      </td>
    </tr>
  );
});

export default TeacherRow;
