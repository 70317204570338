import { AMPLITUDE_KEY } from "../config";
import { EVENT_CATEGORY_MAPPING } from "../constants";
import {
  init,
  track,
  setDeviceId,
  reset,
  setOptOut,
  setUserId,
  Identify,
  identify
} from "@amplitude/analytics-browser";

const userProperties = new Identify();

export const initAmplitude = () => {
  init(AMPLITUDE_KEY);
};

export const setAmplitudeUserDevice = deviceId => {
  setDeviceId(deviceId);
};

export const setAmplitudeUserId = userId => {
  setUserId(userId);
};

export const setAmplitudeUserProperties = properties => {
  const propertyCombinations = Object.entries(properties);
  propertyCombinations?.forEach(([key, value]) => setAmplitudeUserProperty(key, value));
  identify(userProperties);
};

const setAmplitudeUserProperty = (key, value) => {
  userProperties.set(key, value);
};

export const resetAmplitude = () => {
  reset();
};

export const OptUserOutOfAmplitudeTracking = () => {
  setOptOut(true);
};

export const trackAmplitudeEvent = (eventType, eventProperties) => {
  const fullEventProperties = { ...eventProperties, product: "dashboard", category: EVENT_CATEGORY_MAPPING[eventType] };
  track(eventType, fullEventProperties);
};
