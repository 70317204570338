import React, { useState } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";
import { FiUser } from "react-icons/fi";
import { ENV } from "../../config";
import useModal from "../../hooks/useModal";
import UserStore from "../../stores/UserStore";
import { Spinner, EditShippingDatesModal as EditShippingDatesModalBody } from "../../components/-common";
import SwitchProfilePictureModalBody from "./SwitchProfilePictureModal";
import UpdateTeacherInfoModalBody from "./UpdateTeacherInfoModal";
import "./Profile.scss";

const PictureRow = observer(({ openSwitchProfilePictureModal }) => {
  const { profilePicture, firstName, lastName, email } = UserStore?.user || {};

  const [pictureError, setPictureError] = useState(false);
  const markPictureError = () => setPictureError(true);

  const pictureComponent = pictureError ? (
    <div className="picture placeholder" onClick={openSwitchProfilePictureModal}>
      <FiUser size={108} />
    </div>
  ) : (
    <img
      className="picture"
      alt="My profile."
      src={profilePicture}
      onError={markPictureError}
      onClick={openSwitchProfilePictureModal}
    />
  );

  return (
    <div className="picture-row">
      {pictureComponent}
      <div className="primary-info">
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
});

const PersonalInformation = observer(() => {
  const { firstName, lastName, email, address, phone } = UserStore?.user || {};
  const { line1, line2, locality: city, region: state, zipCode } = address || {};

  const addressLine2 = line2 ? (
    <div className="row cont">
      <div className="title" />
      <div className="value">{line2}</div>
    </div>
  ) : null;

  return (
    <div className="section personal-info">
      <div className="section-contents">
        <div className="header">Personal Information</div>
        <div className="row">
          <div className="title">Name</div>
          <div className="value">
            {firstName} {lastName}
          </div>
        </div>
        <div className="row">
          <div className="title">Address</div>
          <div className="value">{line1}</div>
        </div>
        {addressLine2}
        <div className="row cont">
          <div className="title"></div>
          <div className="value">
            {city}, {state} {zipCode}
          </div>
        </div>
        <div className="row">
          <div className="title">Home Phone</div>
          <div className="value">{phone?.number}</div>
        </div>
        <div className="row">
          <div className="title">Email Address</div>
          <div className="value">{email}</div>
        </div>
      </div>
    </div>
  );
});

const Actions = observer(({ openSwitchProfilePictureModal }) => {
  const { teacher, teacherProfile } = UserStore?.user || {};

  const { Modal: UpdateTeacherInfoModal, openModal: openUpdateTeacherInfoModal } = useModal(
    UpdateTeacherInfoModalBody,
    { teacherInfo: teacherProfile },
    { portalElementId: "update-teacher-info-modal", big: true }
  );

  const { Modal: EditShippingDatesModal, openModal: openEditShippingDatesModal } = useModal(
    EditShippingDatesModalBody,
    { teacherInfo: teacherProfile },
    { portalElementId: "edit-shipping-dates-modal" }
  );

  const updateTeacherInfoAction =
    teacher && teacherProfile ? (
      <div className="row action">
        <div className="action" onClick={openUpdateTeacherInfoModal}>
          <span>Update Teacher Information</span>
        </div>
      </div>
    ) : null;

  const updateShippingDatesAction =
    teacher && teacherProfile ? (
      <div className="row action">
        <div className="action" onClick={openEditShippingDatesModal}>
          <span>Manage Shipping Dates</span>
        </div>
      </div>
    ) : null;

  return (
    <div className="section">
      <div className="section-contents">
        <div className="header">Actions</div>
        <div className="row action">
          <div className="action" onClick={openSwitchProfilePictureModal}>
            <span>Switch Profile Picture</span>
          </div>
        </div>
        <div className="row action">
          <div className="action">
            <a
              href={`https://playmusic.infusionsoft.app/app/form/change-in-personal-info-for-parents`}
              rel="noreferrer"
              target="_blank"
            >
              Update Address
            </a>
          </div>
        </div>
        {updateTeacherInfoAction}
        {updateShippingDatesAction}
        <UpdateTeacherInfoModal />
        <EditShippingDatesModal />
      </div>
    </div>
  );
});

const TeacherInformation = observer(() => {
  const { id: teacherId, infusionsoftId, teacherProfile } = UserStore?.user || {};
  const { bio, testimonials, locations, website, video } = teacherProfile || {};

  if (!teacherProfile) return <></>;

  const studioLocationLines = locations
    ?.map(({ name, line1, line2, locality: city, region: state, zipCode }, i, allLocations) => {
      const isLast = i === allLocations?.length - 1;
      const addressLine2 = line2 ? (
        <div className="row cont" key={`${name}-line2`}>
          <div className="title" />
          <div className="value">{line2}</div>
        </div>
      ) : null;
      return [
        <div className="row" key={`${name}-title`}>
          <div className="title">{i === 0 ? "Studio Locations" : null}</div>
          <div className="value">{name}</div>
        </div>,
        <div className="row cont" key={`${name}-line1`}>
          <div className="title" />
          <div className="value">{line1}</div>
        </div>,
        addressLine2,
        <div className="row cont" key={`${name}-citystatezip`}>
          <div className="title" />
          <div className="value">
            {city}, {state} {zipCode}
          </div>
        </div>,
        isLast ? null : <div className="row separator" />
      ]?.filter(Boolean);
    })
    .flat();

  const enrollmentLink = `https://enrollment${ENV === "dev" ? ".dev" : ""}.letsplaymusicsite.com/teachers/${teacherId}`;

  return (
    <div className="section">
      <div className="section-contents">
        <div className="header">Teacher Information</div>
        <div className="row">
          <div className="title">My Enrollment ID</div>
          <div className="value">{infusionsoftId}</div>
        </div>
        <div className="row">
          <div className="title">My Enrollment Link</div>
          <div className={`value${bio ? "" : " empty"}`}>
            <a href={enrollmentLink} target="_blank" rel="noreferrer">
              {enrollmentLink}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="title">Bio</div>
          <div className={`value${bio ? "" : " empty"}`}>{bio || "(none yet)"}</div>
        </div>
        {studioLocationLines}
        <div className="row">
          <div className="title">Testimonials</div>
          <div className={`value${testimonials ? "" : " empty"}`}>{testimonials || "(none yet)"}</div>
        </div>
        <div className="row">
          <div className="title">Website</div>
          <div className={`value${website ? "" : " empty"}`}>{website || "(none yet)"}</div>
        </div>
        <div className="row">
          <div className="title">Video Link</div>
          <div className={`value${video ? "" : " empty"}`}>{video || "(none yet)"}</div>
        </div>
      </div>
    </div>
  );
});

const ShippingDatesInformation = observer(() => {
  const { summer, fall, winter } = UserStore?.user?.teacherProfile?.shippingDates || {};
  const summerWindow = summer ? format(parseISO(summer), "MMM do, yyyy") : "-";
  const fallWindow = fall ? format(parseISO(fall), "MMM do, yyyy") : "-";
  const winterWindow = winter ? format(parseISO(winter), "MMM do, yyyy") : "-";

  return (
    <div className="section">
      <div className="section-contents">
        <div className="header">Shipping Dates</div>
        <div className="row">
          <div className="title">Spring/Summer window</div>
          <div className="value">{summerWindow}</div>
        </div>
        <div className="row">
          <div className="title">Fall window</div>
          <div className="value">{fallWindow}</div>
        </div>
        <div className="row">
          <div className="title">Winter window</div>
          <div className="value">{winterWindow}</div>
        </div>
      </div>
    </div>
  );
});

function Profile() {
  const { Modal: SwitchProfilePictureModal, openModal: openSwitchProfilePictureModal } = useModal(
    SwitchProfilePictureModalBody,
    { current: UserStore?.user?.profilePicture },
    { portalElementId: "switch-profile-picture-modal" }
  );

  if (UserStore?.loading) {
    return (
      <div className="profile profile-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="title-row">
        <div className="title">My Profile</div>
      </div>
      <div className="content">
        <PictureRow {...{ openSwitchProfilePictureModal }} />
        <div className="sections-wrapper">
          <PersonalInformation />
          <Actions {...{ openSwitchProfilePictureModal }} />
          <TeacherInformation />
          <ShippingDatesInformation />
        </div>
      </div>
      <SwitchProfilePictureModal />
    </div>
  );
}

export default observer(Profile);
