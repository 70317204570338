import { request } from "../utils";
import UserStore from "../stores/UserStore";

async function sendFeedback(feedback) {
  try {
    const { impersonatingUserId: userId } = UserStore || {};
    const headers = userId ? { "X-Lpm-Impersonate-User": userId } : null;
    await request.post("/feedback", { body: { feedback, metadata: { platform: "dashboard" } }, headers });
    return true;
  } catch (err) {
    return false;
  }
}

export default sendFeedback;
