import React, { useState } from "react";
import { observer } from "mobx-react";
import ClassesStore from "../../../../stores/ClassesStore";
import { Button, Input, Spinner } from "../../../-common";
import "./ChangeClassNameModal.scss";

function ChangeClassNameModal({ close, currentClass }) {
  const classId = currentClass?.id || currentClass?.classId;
  if (!classId) close();

  const [loading, setLoading] = useState(false);

  const [className, setClassName] = useState(currentClass?.name);
  const updateClassName = ({ target }) => setClassName(target.value);

  const changeClassName = async () => {
    setLoading(true);
    await ClassesStore.editClass(classId, { name: className });
    setLoading(false);
    close();
  };

  const submitDisabled = !className || currentClass?.name === className;
  const submitButtonContent = loading ? <Spinner /> : "Change Name";

  return (
    <div className="change-class-name-modal">
      <div className="content">
        <div className="title">Change Class Name</div>

        <div className="section-container double">
          <div className="small">
            <div className="section-title">New Name</div>
            <Input value={className} onChange={updateClassName} placeholder="New name for this class" />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={changeClassName}
            disabled={submitDisabled}
          >
            {submitButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ChangeClassNameModal);
