import React from "react";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

const DatePicker = ({ value, onChange, ...props }) => {
  return (
    <div className="lpm-datepicker">
      <Picker
        {...props}
        selected={value}
        onChange={onChange}
        placeholderText={props.placeholder || props.placeholderText}
      />
    </div>
  );
};

export default DatePicker;
