import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiUser } from "react-icons/fi";
import useModal from "../../../hooks/useModal";
import UpdateChildModalBody from "../UpdateChildModal";
import "./ChildRow.scss";

const LastClassCell = ({ period, sessionTime, courseLogo, teacher }) => {
  const [courseLogoError, setCourseLogoError] = useState(false);
  const markCourseLogoError = () => setCourseLogoError(true);
  const courseLogoComponent = courseLogoError ? (
    <div className={`course-logo placeholder`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img className="course-logo" src={courseLogo} alt="Course logo" onError={markCourseLogoError} />
  );

  return (
    <div className="cell-contents flex-center">
      {courseLogoComponent}
      <div>
        <div>
          with {teacher?.firstName} {teacher?.lastName}
        </div>
        <div className="aux">{period}</div>
        <div className="aux">{sessionTime}</div>
      </div>
    </div>
  );
};

const ChildRow = observer(({ child }) => {
  const { profilePicture, gender, firstName, lastName, notes, birthdayText, lastClass } = child || {};

  const { Modal: UpdateChildModal, openModal: openUpdateChildModal } = useModal(
    UpdateChildModalBody,
    { child },
    { portalElementId: "update-child-modal" }
  );

  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);
  const profilePictureComponent = profilePictureError ? (
    <div className={`profile-picture placeholder${gender ? " " + gender : ""}`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img className="profile-picture" src={profilePicture} alt="Student profile" onError={markProfilePictureError} />
  );

  const lastClassContents = lastClass ? <LastClassCell {...lastClass} /> : <div className="cell-contents empty">-</div>;

  // const moreSuppliesAction =
  //   !lastClass || lastClass?.status === "ended" ? null : <div className="action">Order More Supplies</div>;

  return (
    <tr>
      <td>
        <div className="cell-contents flex-center">
          {profilePictureComponent}
          <div>
            <div>
              {firstName} {lastName}
            </div>
            <div className="aux">{birthdayText}</div>
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents notes">{notes || <span className="aux">(no notes)</span>}</div>
      </td>
      <td>{lastClassContents}</td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={openUpdateChildModal}>
            Update Child
          </div>
          {/* {moreSuppliesAction} */}
        </div>
      </td>
      <UpdateChildModal />
    </tr>
  );
});

export default ChildRow;
