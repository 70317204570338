import React, { useRef } from "react";
import "./FileSelect.scss";

const defaultAccept = "*";

const FileSelect = ({ filename, updateFile, accept }) => {
  const fileInputRef = useRef();

  const showFileSelector = () => fileInputRef?.current?.click();

  return (
    <div className="file-select">
      <div className="file-upload-zone" onClick={showFileSelector}>
        <div className="file-upload-zone-inner">
          <div className="file-upload-zone-label">{filename || "Click here to upload"}</div>
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept || defaultAccept}
        className="hidden-file-input"
        onChange={updateFile}
      />
    </div>
  );
};

export default FileSelect;
