import React, { useState } from "react";
import { observer } from "mobx-react";
import { Input, Spinner } from "../../../components/-common";
import useQueryParams from "../../../hooks/useQueryParams";
import useOnEnterPressed from "../../../hooks/useOnEnterPressed";
import AuthStore from "../../../stores/AuthStore";
import "./VerifyAccount.scss";

function VerifyAccount() {
  const { p: authParams } = useQueryParams();

  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const [error, setError] = useState();

  const verifyEnabled = code && code.length === 6;

  const submit = async () => {
    if (verifyEnabled) {
      setError(null);
      try {
        const { email, password } = JSON.parse(atob(authParams));
        const { success, error } = await AuthStore.verifyAccount(email, password, code);
        if (!success && error) setError(error);
      } catch (err) {
        console.warn(err);
      }
    }
  };
  useOnEnterPressed(submit);

  const resendCode = async () => {
    const { email } = JSON.parse(atob(authParams));
    await AuthStore.resendVerificationCode(email);
  };

  const buttonContents = AuthStore.loading ? <Spinner /> : "VERIFY";
  const buttonStyles = verifyEnabled
    ? AuthStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  return (
    <div className="verify-account">
      {/* <BackgroundBlobs /> */}
      <div className="auth-content">
        <div className="message">
          Nice! We sent a verification code to your email - enter it here and we'll get you going.
        </div>
        <h3 className="auth-title">Verify</h3>
        <div className="auth-square">
          <div className="auth-inputs">
            <Input placeholder="Verification Code" value={code} onChange={updateCode} error={error} />
          </div>
          <button style={buttonStyles} className="auth-button" onClick={submit}>
            {buttonContents}
          </button>
          <div className="redirect" onClick={resendCode}>
            <span>Didn't get a code?</span>
            <span className="auth-link">Send Another</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(VerifyAccount);
