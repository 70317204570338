import React from "react";
import RSelect from "react-select";

// TODO: make the row colors match our theme
const selectStyles = {
  container: provided => ({
    ...provided,
    width: "100%"
  }),
  control: provided => ({
    ...provided,
    border: "none",
    boxShadow: "var(--shadow)",
    borderRadius: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: "var(--medium-gray)",
    opacity: 0.5
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "var(--dark-primary)" : state.isFocused ? "#f7f6f6" : "#fff",
      ...(state.isSelected ? { fontWeight: "700" } : {}),
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "var(--dark-primary)" : "var(--dark-primary-transparent)"
      }
    };
  }
};

const Select = props => {
  const options = (props?.options || []).map(o => ({ label: o, value: o }));
  const value = props?.value != null ? { label: props.value, value: props.value } : null;
  const onChange = selection => {
    props?.onChange && props.onChange(selection?.value);
  };

  return (
    <RSelect styles={selectStyles} {...props} options={options} value={value} onChange={onChange} isMulti={false} />
  );
};

export default Select;
