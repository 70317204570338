import { observer } from "mobx-react";
import "./ThankYou.scss";

const ThankYou = observer(() => {
  return (
    <div className="thank-you-wrapper">
      <div className="content">
        <div className="title">Thank you!</div>
        <div className="message">You're all set - you should get an emailed receipt really soon.</div>
        <div className="materials-reminder">
          As a reminder, your materials will be delivered to your teacher who will distribute them to you once they've
          arrived.
        </div>
      </div>
    </div>
  );
});

export default ThankYou;
