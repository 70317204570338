import React from "react";
import "./Spinner.scss";

const Spinner = ({ alt, secondary, action }) => (
  <div className={`lds-ellipsis${alt ? " alt" : ""}${secondary ? " secondary" : ""}${action ? " action" : ""}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Spinner;
