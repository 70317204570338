import React, { useState } from "react";
import { observer } from "mobx-react";
import UserStore from "../../../stores/UserStore";
import { Spinner, Button, Select, FileSelect } from "../../-common";
import "./UploadFileModal.scss";

const fileTypeOptions = [
  "FBI Fingerprint Clearance",
  "Proof of Insurance Coverage",
  "Sound Beginnings Certification",
  "LPM 1st Year Certfication",
  "LPM 2nd Year Certification",
  "LPM 3rd Year Certification",
  "Presto Year I Certification",
  "Presto Year II Certification",
  "Recertification / Reactivation"
];

function UploadFileModal({ close }) {
  const [fileType, setFileType] = useState();
  const [uploading, setUploading] = useState(false);

  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      setFilename(selectedFile.name);
      setFile(selectedFile);
    }
  };

  const uploadEnabled = fileType && file;
  const upload = async () => {
    if (uploadEnabled && fileType) {
      setUploading(true);
      const fileParams = { file, type: fileType };
      const success = await UserStore.uploadFile(fileParams);
      setUploading(false);
      if (success) close();
    }
  };

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = uploadEnabled
    ? uploading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = uploading ? <Spinner /> : "Upload File";

  return (
    <div className="add-file-modal">
      <div className="content">
        <div className="title">Upload or Verify</div>
        <div className="section-container columns">
          <div className="col">
            <div className="input-wrapper">
              <div className="input-title">File Type</div>
              <Select options={fileTypeOptions} value={fileType} onChange={setFileType} maxMenuHeight={400} />
            </div>
          </div>
          <div className="col">
            <div className="file-select-container">
              <FileSelect {...{ filename, updateFile }} />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={upload} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(UploadFileModal);
