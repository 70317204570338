import { makeAutoObservable, autorun } from "mobx";
import { ENV } from "../config";
import { request } from "../utils";
import { setAmplitudeUserProperties } from "../services/AmplitudeService";
import AuthStore from "./AuthStore";
import ClassesStore from "./ClassesStore";

class UserStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchUser();
        this.fetchChildrenForUser();
        this.fetchFilesForUser();
      } else {
        this.clear();
      }
    });
  }

  loading = false;

  rawUser = {};

  get user() {
    if (!this?.rawUser) return null;

    const id = this.rawUser?.id || AuthStore?.id;
    const infusionsoftId = this.rawUser?.infusionsoftId || AuthStore?.infusionsoftId;
    const email = this.rawUser?.email?.[0]?.email || AuthStore?.email;
    const emails = this.rawUser?.email;
    const address = this.rawUser?.addresses?.[0];
    const addresses = this.rawUser?.addresses;
    const phone = this.rawUser?.phone?.[0];
    const phones = this.rawUser?.phone;
    const profilePicture = `https://${ENV}-lpm-assets.b-cdn.net/profiles/${id}?m=${this.rawUser.modified}`;
    const registrations = this.rawUser?.registrations?.map(r => ({
      ...r,
      courseLogoURL: `https://${ENV}-lpm-assets.b-cdn.net/icons/${r.courseId}?m=${r.class?.modified}`,
      class: {
        ...(r?.class || {}),
        teacher: {
          ...(r?.class?.teacher || {}),
          pageURL: `https://enrollment${ENV === "prod" ? "" : ENV + "."}.letsplaymusicsite.com/teachers/${
            r.class?.teacher?.id
          }`
        }
      }
    }));

    return {
      ...this.rawUser,
      id,
      infusionsoftId,
      email,
      emails,
      address,
      addresses,
      phone,
      phones,
      profilePicture,
      registrations
    };
  }

  loadingChildren = false;
  loadingFiles = false;
  loadingCards = false;
  creatingCard = false;

  rawChildren = [];
  rawFiles = [];

  rawCards = null;

  get children() {
    return this.rawChildren.map(c => ({
      ...c,
      profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${c?.id}?m=${c?.modified}`,
      lastClass: c?.lastClass
        ? {
            ...c.lastClass,
            courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${c.lastClass?.courseId}?m=${c.lastClass?.modified}`
          }
        : null
    }));
  }

  get files() {
    return this.rawFiles.map(f => ({ ...f }));
  }

  get cards() {
    if (!this?.rawUser) return [];
    return this.rawCards?.map(c => ({
      ...c,
      label: `${c?.card_type} ending in ${c?.card_number?.replace(/x/g, "")}`
    }));
  }

  async fetchUser() {
    this.loading = true;
    try {
      const user = await request.get(`/users/me`);
      const { teacher: isTeacher, parent: isParent } = user || {};
      setAmplitudeUserProperties({ ...(isTeacher ? { isTeacher } : {}), ...(isParent ? { isParent } : {}) });
      this.rawUser = user;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async fetchChildrenForUser() {
    this.loadingChildren = true;
    try {
      const children = await request.get(`/children`);
      this.rawChildren = children;
      this.loadingChildren = false;
    } catch (err) {
      console.warn(err);
      this.loadingChildren = false;
    }
  }

  async fetchFilesForUser() {
    this.loadingFiles = true;
    try {
      const files = await request.get(`/users/me/files`);
      this.rawFiles = files;
      this.loadingFiles = false;
    } catch (err) {
      console.warn(err);
      this.loadingFiles = false;
    }
  }

  async fetchCardsForUser() {
    this.loadingCards = true;
    try {
      const cards = await request.get(`/users/me/cards`);
      this.rawCards = cards;
      this.loadingCards = false;
      return cards;
    } catch (err) {
      console.warn(err);
      this.loadingCards = false;
    }
  }

  async updateUser(updates, { background } = {}) {
    if (!this?.user?.id) return;

    if (!background) this.loading = true;
    try {
      const user = await request.put(`/users/me`, { body: updates });
      this.rawUser = user;
      if (!background) this.loading = false;
      return user;
    } catch (err) {
      console.warn(err);
      if (!background) this.loading = false;
    }
  }

  async updateProfilePicture(profilePicture) {
    if (this?.user?.id) {
      try {
        const fileData = await request.get(`/files/profile?userId=${this.user.id}`, {
          headers: { "file-type": profilePicture.type }
        });

        const { uploadURL } = fileData || {};
        const uploadResponse = await fetch(uploadURL, {
          method: "PUT",
          body: profilePicture,
          headers: { "Content-Type": profilePicture.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Upload failed.");

        this.rawUser = { ...this.rawUser, modified: new Date().toISOString() };
        this.updateUser({}, { background: true });
      } catch (err) {
        console.warn("Error updating profile picture:", err);
        throw err;
      }
    }
  }

  async updateTeacherProfile(teacherProfileUpdates) {
    if (!this?.user?.id) return;
    try {
      const updatedTeacherProfile = await request.put(`/users/me/teacher`, { body: teacherProfileUpdates });
      this.rawUser = { ...this.rawUser, teacherProfile: updatedTeacherProfile };
      this.loading = false;
      return updatedTeacherProfile;
    } catch (err) {
      console.warn(err);
    }
  }

  async updateShippingDatesForTeacher({ shippingDates }) {
    if (!this?.user?.id) return;
    try {
      const updatedTeacherProfile = await request.put(`/users/me/teacher/shipping`, { body: { shippingDates } });
      this.rawUser = { ...this.rawUser, teacherProfile: updatedTeacherProfile };
      this.loading = false;
      await ClassesStore.fetchClasses(true);
      return updatedTeacherProfile;
    } catch (err) {
      console.warn(err);
    }
  }

  async createChild(childParams) {
    try {
      const newChild = await request.post(`/accounts/students`, { body: childParams });
      this.rawChildren = (this.rawChildren || [])?.concat(newChild);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async updateChild(childId, updates) {
    const currentChildren = this.rawChildren.slice();
    try {
      this.rawChildren = this.rawChildren?.map(c => {
        if (c?.id === childId) return { ...c, ...updates };
        return c;
      });
      const newChild = await request.put(`/accounts/students/${childId}`, { body: updates });
      this.rawChildren = this.rawChildren?.map(c => {
        if (c?.id === childId) return { ...c, ...newChild };
        return c;
      });
      return true;
    } catch (err) {
      this.rawChildren = currentChildren;
      console.warn(err);
      return false;
    }
  }

  async uploadFile({ file, type }) {
    if (this?.user?.id && type) {
      try {
        const { newTeacherFile, uploadURL } = await request.post(`/users/me/files`, {
          body: { filename: file ? file.name : null, type }
        });

        if (uploadURL) {
          const uploadResponse = await fetch(uploadURL, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
          });
          if (!uploadResponse.ok) {
            await request.delete(`/users/me/files/${newTeacherFile?.id}`);
            throw new Error("Upload failed.");
          }
        }

        if (newTeacherFile) {
          this.rawFiles = this.rawFiles?.concat(newTeacherFile);
          this.updateUser({}, { background: true });
        }

        return true;
      } catch (err) {
        console.warn("Error uploading new file:", err);
      }
    }
  }

  async createCard({ number, brand, expMonth, expYear, name, cvv }) {
    this.creatingCard = true;
    try {
      const newCard = await request.post(`/users/me/cards`, {
        body: {
          cardNumber: number,
          cardType: brand,
          emailAddress: this.user?.email,
          expirationMonth: expMonth,
          expirationYear: expYear,
          cardName: name,
          verificationCode: cvv
        }
      });
      this.rawCards = this.rawCards?.concat(newCard);
      return newCard;
    } catch (err) {
      console.warn(err);
      this.creatingCard = false;
    }
  }

  clear() {
    this.rawUser = null;
  }
}

export default new UserStore();
