import React, { useState } from "react";
import { observer } from "mobx-react";
import { parseISO, format } from "date-fns";
import { FiCalendar, FiUser } from "react-icons/fi";
import UserStore from "../../../stores/UserStore";
import { Button, DatePicker, Input, Select, Spinner, TextArea } from "../../-common";
import "./UpdateChildModal.scss";

function UpdateChildModal({ child, close }) {
  const [firstName, setFirstName] = useState(child?.firstName);
  const updateFirstName = ({ target }) => setFirstName(target.value);

  const [lastName, setLastName] = useState(child?.lastName);
  const updateLastName = ({ target }) => setLastName(target.value);

  const [gender, setGender] = useState(child?.gender);

  const [birthday, setBirthday] = useState(child?.birthday ? parseISO(child?.birthday) : null);

  const [notes, setNotes] = useState(child?.notes);
  const updateNotes = ({ target }) => setNotes(target.value);

  const [loading, setLoading] = useState(false);

  let birthdayChanged = false;
  if (birthday && child?.birthday) {
    birthdayChanged = format(birthday, "yyyy-MM-dd") !== format(parseISO(child?.birthday), "yyyy-MM-dd");
  } else if (birthday) {
    birthdayChanged = true;
  }

  const updateEnabled =
    firstName !== child?.firstName ||
    lastName !== child?.lastName ||
    gender !== child?.gender ||
    birthdayChanged ||
    notes !== child?.notes;
  const updateChild = async () => {
    if (updateEnabled) {
      setLoading(true);
      await UserStore?.updateChild(child?.id, {
        firstName,
        lastName,
        gender,
        birthday: format(birthday, "yyyy-MM-dd"),
        notes
      });
      setLoading(false);
      close();
    }
  };

  const sendButtonContent = loading ? <Spinner /> : "Update";

  return (
    <div className="update-child-modal">
      <div className="content">
        <div className="title">Update Child</div>
        <div className="input-wrapper">
          <div className="inputs-row-title">Name</div>
          <div className="inputs-row">
            <Input type="text" placeholder="First" value={firstName} onChange={updateFirstName} />
            <Input type="text" placeholder="Last" value={lastName} onChange={updateLastName} />
          </div>
        </div>
        <div className="inputs-row">
          <div className="inputs-row-section">
            <div className="inputs-row-title">Gender</div>
            <Select
              icon={FiUser}
              placeholder="Select..."
              options={["Female", "Male"]}
              value={gender}
              onChange={setGender}
            />
          </div>
          <div className="inputs-row-section">
            <div className="inputs-row-title">Birthday</div>
            <DatePicker icon={FiCalendar} placeholderText="ex. 01/01/2016" value={birthday} onChange={setBirthday} />
          </div>
        </div>
        <div className="inputs-row">
          <div className="inputs-row-section">
            <div className="inputs-row-title">Notes</div>
            <TextArea
              placeholder="Put any additional important information here (allergies, medical, conditions, etc.)"
              value={notes}
              onChange={updateNotes}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={updateChild}
            disabled={!updateEnabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(UpdateChildModal);
