import { makeAutoObservable, autorun } from "mobx";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import UserStore from "./UserStore";

class MaterialsStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchMaterials();
      } else this.clear();
    });
  }

  loading = false;

  rawMaterials = [];

  get materials() {
    return this.rawMaterials.map(s => s);
  }

  get products() {
    return (this.materials || [])
      .filter(
        ({ name, price }) => !name?.toLowerCase().includes("tax") && !name?.toLowerCase().match(/^fee\s-/) && price > 0
      )
      ?.sort((a, b) => (a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1));
  }

  get materialsByProductId() {
    return Object.fromEntries(this.rawMaterials?.map(m => [m?.id, m]));
  }

  async fetchMaterials() {
    this.loading = true;
    try {
      const materials = await request.get(`/materials`);
      // const materials = await new Promise(r => setTimeout(r([]), 1500));
      this.rawMaterials = materials;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async orderReplacementMaterials({ teacher: fullTeacher, class: classObject, products, card }) {
    try {
      const { id: userId, infusionsoftId: userInfusionsoftId } = UserStore?.user || {};
      const parent = { id: userId, infusionsoftId: userInfusionsoftId };
      const teacher = { id: fullTeacher?.id, infusionsoftId: fullTeacher?.infusionsoftId };
      const simplifiedClass = { id: classObject?.id || classObject?.classId, courseId: classObject?.courseId };
      const order = { parent, teacher, class: simplifiedClass, products };
      await request.post(`/materials/reorder`, { body: { order, card: { id: card?.id } } });
      return { success: true };
    } catch (err) {
      let error;
      const { message } = err?.response?.data || {};
      if (message === "Error processing payment") {
        error =
          "Looks like there was an issue processing your card. Check your inputs and try again, or use another card.";
      } else {
        error =
          "Looks like something went wrong. If this keeps happening, please contact us at support@letsplaymusicsite.com";
      }
      return { success: false, error };
    }
  }

  clear() {
    this.rawMaterials = [];
  }
}

export default new MaterialsStore();
