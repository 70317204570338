import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import "./TextArea.scss";

const RegularTextarea = props => <textarea {...props} />;

const TextArea = ({ height = 250, placeholder, value, onChange, error, disabled, autogrow, ...rest }) => {
  const InputComponent = autogrow ? TextareaAutosize : RegularTextarea;

  const errorMessage = error ? <p className="lpm-input-error">{error}</p> : null;

  const errorIcon = error ? (
    <svg className="lpm-input-error-icon" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#E0614A" />
      <line x1="5.5" y1="5.5" x2="10.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="10.5" y1="5.5" x2="5.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ) : null;

  const errorStyles = error
    ? {
        borderRight: "6px solid #E0614A"
      }
    : {};
  return (
    <div className={`lpm-textarea${disabled ? " disabled" : ""}`}>
      <InputComponent
        {...rest}
        style={{ ...errorStyles, height }}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange || (() => null)}
      />
      {errorIcon}
      {errorMessage}
    </div>
  );
};

export default TextArea;
