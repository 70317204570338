import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import { parseISO, format } from "date-fns";
import { FiChevronRight } from "react-icons/fi";
import useModal from "../../../hooks/useModal";
import useClass from "../../../hooks/useClass";
import ClassesStore from "../../../stores/ClassesStore";
import { Spinner } from "../../-common";
import ChangeClassNameModalBody from "./ChangeClassNameModal";
import EditRegistrationDetailsModalBody from "./EditRegistrationDetailsModal";
import EditScheduleDetailsModalBody from "./EditScheduleDetailsModal";
import ConfirmDeleteClassModalBody from "./ConfirmDeleteClassModal";
import StudentsTable from "./StudentsTable";
import "./ClassDetails.scss";

function ClassDetails({ classId }) {
  const currentClass = useClass(classId);
  const { loading, courseId, name, capacity, openSeats, students, shippingDate } = currentClass || {};

  const [downloading, setDownloading] = useState(false);

  const formattedShippingDate =
    shippingDate && shippingDate !== "0000-00-00" ? format(parseISO(shippingDate), "MMM do, yyyy") : "-";

  const downloadRoster = async () => {
    setDownloading(true);
    await ClassesStore?.downloadRosterForClass(classId);
    setDownloading(false);
  };

  const { Modal: ChangeClassNameModal, openModal: openChangeClassNameModal } = useModal(
    ChangeClassNameModalBody,
    { currentClass },
    { portalElementId: "change-class-name-modal" }
  );

  const { Modal: EditRegistrationDetailsModal, openModal: openEditRegistrationDetailsModal } = useModal(
    EditRegistrationDetailsModalBody,
    { currentClass },
    { portalElementId: "edit-registration-details-modal" }
  );

  const { Modal: EditScheduleDetailsModal, openModal: openEditScheduleDetailsModal } = useModal(
    EditScheduleDetailsModalBody,
    { currentClass },
    { portalElementId: "edit-schedule-details-modal" }
  );

  const { Modal: ConfirmDeleteClassModal, openModal: openConfirmDeleteClassModal } = useModal(
    ConfirmDeleteClassModalBody,
    { class: currentClass },
    { portalElementId: "confirm-delete-class-modal" }
  );

  // const [searchText, setSearchText] = useState("");
  // const updateSearchText = ({ target }) => setSearchText(target?.value);

  if (loading) {
    return (
      <div className="class-details class-details-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const uniqueParents = [...new Set(students?.map(s => s?.parent?.id))];

  const deleteClassAction =
    students?.length === 0 ? (
      <div className="action-row">
        <div className="action" onClick={openConfirmDeleteClassModal}>
          <span>Delete Class</span>
        </div>
      </div>
    ) : null;

  const downloadButtonContent = downloading ? (
    <div className="action-loading-container">
      <Spinner action />
    </div>
  ) : (
    <span>Download Roster</span>
  );

  return (
    <div className="class-details">
      <div className="title-row">
        <Link to="/classes" className="title">
          Classes
        </Link>
        <FiChevronRight size={36} className="title-arrow" />
        <div className="title">{name}</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Class Info</div>
          <div className="info-row">
            <div className="info-title">Requested Shipping Date</div>
            <div className="info-value">{formattedShippingDate}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Capacity</div>
            <div className="info-value">{capacity}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Seats Available</div>
            <div className="info-value">{openSeats}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Students</div>
            <div className="info-value">{students?.length}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Parents</div>
            <div className="info-value">{uniqueParents?.length}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openChangeClassNameModal}>
              <span>Change Class Name</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openEditRegistrationDetailsModal}>
              <span>Edit Registration Details</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openEditScheduleDetailsModal}>
              <span>Update Schedule Details</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={downloadRoster}>
              {downloadButtonContent}
            </div>
          </div>
          {deleteClassAction}
        </div>
      </div>
      <div className="filter-search-row">
        <div className="header">Roster</div>
        {/* <div className="search-students">
          <Input icon={FiSearch} placeholder="Search students..." value={searchText} onChange={updateSearchText} />
        </div> */}
      </div>
      <div className="table-wrapper">
        <StudentsTable {...{ class: currentClass, students, classId, courseId }} />
      </div>
      <ChangeClassNameModal />
      <EditRegistrationDetailsModal />
      <EditScheduleDetailsModal />
      <ConfirmDeleteClassModal />
    </div>
  );
}

export default observer(ClassDetails);
