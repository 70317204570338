import React, { useState } from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { FiLink, FiVideo, FiX, FiPlus } from "react-icons/fi";
import { STATES } from "../../../constants";
import UserStore from "../../../stores/UserStore";
import { Spinner, Input, TextArea, Select, Button } from "../../-common";
import "./UpdateTeacherInfoModal.scss";

const LocationRow = ({ i, location, updateLocation, removeLocation }) => {
  const updateName = ({ target }) => updateLocation({ ...location, name: target.value });
  const updateLine1 = ({ target }) => updateLocation({ ...location, line1: target.value });
  const updateLine2 = ({ target }) => updateLocation({ ...location, line2: target.value });
  const updateLocality = ({ target }) => updateLocation({ ...location, locality: target.value });
  const updateRegion = region => updateLocation({ ...location, region });
  const updateZip = ({ target }) => updateLocation({ ...location, zipCode: target.value });

  return (
    <div className="location-wrapper">
      <div className="location-number">#{i + 1}</div>
      <div className="location-rows">
        <div className="location-row">
          <Input placeholder="Name for this location" value={location.name} onChange={updateName} />
        </div>
        <div className="location-row">
          <Input placeholder="Address Line 1" value={location.line1} onChange={updateLine1} />
        </div>
        <div className="location-row">
          <Input placeholder="Address Line 2" value={location.line2} onChange={updateLine2} />
        </div>
        <div className="location-row triple">
          <Input placeholder="City" value={location.locality} onChange={updateLocality} />
          <Select placeholder="State/Province" options={STATES} value={location.region} onChange={updateRegion} />
          <Input placeholder="Postal Code" value={location.zipCode} onChange={updateZip} />
        </div>
        <div className="remove-location" onClick={removeLocation}>
          <FiX />
          Remove Location
        </div>
      </div>
    </div>
  );
};

function UpdateTeacherInfoModal({ teacherInfo, close }) {
  const {
    bio: currentBio,
    locations: currentLocations,
    testimonials: currentTestimonials,
    website: currentWebsite,
    video: currentVideo
  } = teacherInfo || {};

  const [bio, setBio] = useState(currentBio);
  const updateBio = ({ target }) => setBio(target.value);

  const [locations, setLocations] = useState(currentLocations || []);
  const addLocation = () => setLocations(locations?.concat({}));
  const removeLocation = i => () => setLocations(locations?.slice(0, i)?.concat(locations?.slice(i + 1)));
  const updateLocation = i => updatedLocation => {
    if (locations?.[i] != null) {
      const newLocations = locations
        ?.slice(0, i)
        ?.concat(updatedLocation)
        ?.concat(locations?.slice(i + 1));
      setLocations(newLocations);
    }
  };

  const [testimonials, setTestimonials] = useState(currentTestimonials);
  const updateTestimonials = ({ target }) => setTestimonials(target.value);

  const [website, setWebsite] = useState(currentWebsite);
  const updateWebsite = ({ target }) => setWebsite(target.value);

  const [video, setVideo] = useState(currentVideo);
  const updateVideo = ({ target }) => setVideo(target.value);

  const [updating, setUpdating] = useState(false);

  const locationsChanged = !isEqual(locations, currentLocations);
  const bioChanged = bio !== currentBio;
  const testimonialsChanged = testimonials !== currentTestimonials;
  const websiteChanged = website !== currentWebsite;
  const videoChanged = video !== currentVideo;
  const changed = locationsChanged || bioChanged || testimonialsChanged || websiteChanged || videoChanged;

  const locationsValid = locations?.every(
    l =>
      !!l?.name &&
      !!l?.line1 &&
      !!l.locality &&
      !!l.region &&
      l?.["zipCode" || "zipcode"]?.match(
        /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/
      )
  );

  const updateEnabled = changed && !!bio && locationsValid;
  const updateTeacherInfo = async () => {
    setUpdating(true);
    await UserStore?.updateTeacherProfile({ bio, locations, testimonials, website, video });
    setUpdating(false);
    close();
  };

  const locationRows = locations?.map((l, i) => (
    <LocationRow
      i={i}
      location={l}
      updateLocation={updateLocation(i)}
      removeLocation={removeLocation(i)}
      key={"location-" + i}
    />
  ));

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update";

  return (
    <div className="update-teacher-info-modal">
      <div className="content">
        <div className="title">Update Teacher Information</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Bio</div>
              <TextArea
                placeholder="Write a little about yourself here"
                value={bio}
                onChange={updateBio}
                autogrow
                rows={8}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Locations</div>
              {locationRows}
              <div className="add-location" onClick={addLocation}>
                <FiPlus /> Add New Location
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">Testimonials</div>
              <TextArea
                placeholder="Add your parent testimonials here"
                value={testimonials}
                onChange={updateTestimonials}
                autogrow
                rows={8}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Website</div>
              <Input
                placeholder="Include a link to a website or blog here"
                value={website}
                onChange={updateWebsite}
                icon={FiLink}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Teaching Video</div>
              <Input
                placeholder="Include a link to a video of you teaching here"
                value={video}
                onChange={updateVideo}
                icon={FiVideo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={updateTeacherInfo} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(UpdateTeacherInfoModal);
