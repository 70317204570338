import React, { useState } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";
import { FiDollarSign, FiHash, FiX } from "react-icons/fi";
import { weekdays, STATES } from "../../../constants";
import { DatePicker, Button, Input, Spinner, Select, Checkbox } from "../../-common";
import CurriculumStore from "../../../stores/CurriculumStore";
import UserStore from "../../../stores/UserStore";
import ClassesStore from "../../../stores/ClassesStore";
import "./AddClassModal.scss";

const START_DATE_WINDOWS = [
  { startOpenDate: "04-01", startCloseDate: "06-30", order: 0, window: "summer" },
  { startOpenDate: "07-01", startCloseDate: "12-31", order: 1, window: "fall" },
  { startOpenDate: "01-01", startCloseDate: "03-31", order: 2, window: "winter" }
];
const WINDOW_LABELS = { summer: "spring/summer", fall: "fall", winter: "winter" };
const RETURNING_COURSES = ["01FHKHHY918W6RQ75D7CDXRDVR", "01FHKHHY91EJV47PT40EVXJTAW", "01FHKHHY918EBX64K2W5TE4H3P"];

// const getApplicableShippingDateWindow = ({ startDate, courseId }) => {
//   const now = new Date();
//   const currentMonth = now.getMonth();
//   const thisYear = now.getFullYear();
//   const nextYear = thisYear + 1;

//   let winterMinStartDate, winterMaxStartDate, summerMinStartDate, summerMaxStartDate, fallMinStartDate, fallMaxStartDate; // prettier-ignore
//   if (10 <= currentMonth || currentMonth <= 1) {
//     const winterMinStartYear = currentMonth > 1 ? thisYear : thisYear - 1;
//     const upcomingYear = currentMonth > 1 ? nextYear : thisYear;
//     winterMinStartDate = parseISO(`${winterMinStartYear}-11-01`);
//     winterMaxStartDate = lastDayOfMonth(parseISO(`${upcomingYear}-02-01`));
//     summerMinStartDate = parseISO(`${upcomingYear}-03-01`);
//     summerMaxStartDate = parseISO(`${upcomingYear}-05-31`);
//     fallMinStartDate = parseISO(`${upcomingYear}-06-01`);
//     fallMaxStartDate = parseISO(`${upcomingYear}-09-30`);
//   } else if (2 <= currentMonth || currentMonth <= 4) {
//     summerMinStartDate = parseISO(`${thisYear}-05-31`);
//     summerMaxStartDate = parseISO(`${thisYear}-05-31`);
//     fallMinStartDate = parseISO(`${thisYear}-06-01`);
//     fallMaxStartDate = parseISO(`${thisYear}-09-30`);
//     winterMinStartDate = parseISO(`${thisYear}-11-01`);
//     winterMaxStartDate = lastDayOfMonth(parseISO(`${nextYear}-02-01`));
//   } else if (5 <= currentMonth || currentMonth <= 9) {
//     fallMinStartDate = parseISO(`${thisYear}-06-01`);
//     fallMaxStartDate = parseISO(`${thisYear}-09-30`);
//     winterMinStartDate = parseISO(`${thisYear}-11-01`);
//     winterMaxStartDate = lastDayOfMonth(parseISO(`${nextYear}-02-01`));
//     summerMinStartDate = parseISO(`${nextYear}-03-01`);
//     summerMaxStartDate = parseISO(`${nextYear}-05-31`);
//   }

//   const startDateWindows = [
//     { startOpenDate: summerMinStartDate, startCloseDate: summerMaxStartDate, order: 0, window: "summer" },
//     { startOpenDate: fallMinStartDate, startCloseDate: fallMaxStartDate, order: 1, window: "fall" },
//     { startOpenDate: winterMinStartDate, startCloseDate: winterMaxStartDate, order: 2, window: "winter" }
//   ];

//   let windowForStartDate = startDateWindows.find(s => {
//     console.log({ s, startDate, jub: s.startOpenDate <= startDate && startDate <= s.startCloseDate });
//     return s.startOpenDate <= startDate && startDate <= s.startCloseDate;
//   });

//   if (RETURNING_COURSES.includes(courseId)) {
//     windowForStartDate = startDateWindows.find(
//       s => s.order === (windowForStartDate.order + startDateWindows?.length - 1) % startDateWindows?.length
//     );
//   }

//   console.log({ windowForStartDate });

//   return windowForStartDate?.window;
// };

const getApplicableShippingDateWindow = ({ startDate, courseId }) => {
  const startDateMonthDay = startDate?.slice(5);
  const classWindowObject = START_DATE_WINDOWS.find(s => {
    return s.startOpenDate <= startDateMonthDay && startDateMonthDay <= s.startCloseDate;
  });

  let shippingWindowObject = classWindowObject;
  if (RETURNING_COURSES.includes(courseId)) {
    shippingWindowObject = START_DATE_WINDOWS.find(
      s => s.order === (shippingWindowObject.order + START_DATE_WINDOWS?.length - 1) % START_DATE_WINDOWS?.length
    );
  }

  return { shippingWindow: shippingWindowObject?.window, classWindow: classWindowObject?.window };
};

const removeCoursesUserIsNotAuthorizedToTeach = teacherProfile => {
  let { canTeachSoundBeginnings, canTeachLPM, canTeachPresto, canTeachOnline, canTeachRecorder } = teacherProfile || {};
  canTeachLPM = true;
  return c => {
    if (c.curriculum?.title === "Sound Beginnings") return canTeachSoundBeginnings;
    if (c.curriculum?.title === "Let's Play Music") return canTeachLPM;
    if (c.curriculum?.title === "Presto") return canTeachPresto;
    if (c.curriculum?.title === "Online") return canTeachOnline;
    if (c.curriculum?.title === "Extras") return canTeachRecorder;
    return false;
  };
};

const ExistingLocation = ({ locations, location, setLocation }) => {
  return (
    <div className="section-container">
      <div className="section-title">Session Location</div>
      <Select options={locations} value={location} onChange={setLocation} />
    </div>
  );
};

const NewLocation = ({
  setNewLocation,
  locationName,
  address,
  updateLocationName,
  updateAddress,
  state,
  city,
  zip,
  updateState,
  updateCity,
  updateZip
}) => {
  const updateNewLocation = () => {
    setNewLocation(false);
  };
  return (
    <div>
      <div className="section-container">
        <div className="section-title">Session Location</div>
        <Input value={locationName} onChange={updateLocationName} placeholder="Location Name" />
      </div>
      <div className="section-container">
        <Input value={address} onChange={updateAddress} placeholder={"Address"} />
      </div>
      <div className="section-container double">
        <div>
          <Input value={city} onChange={updateCity} placeholder={"City"} />
        </div>
        <div>
          <Select options={STATES} value={state} onChange={updateState} placeholder={"State"} />
        </div>
        <div>
          <Input value={zip} onChange={updateZip} placeholder={"Zip"} />
        </div>
      </div>
      <div className="location-buttons-container">
        <div className="use-existing-location" onClick={updateNewLocation}>
          <FiX size={18} />
          <span>Use Existing Location</span>
        </div>
      </div>
    </div>
  );
};

function AddClassModal({ close }) {
  const { teacherProfile } = UserStore?.user || {};
  const { locations: currentLocations, shippingDates } = teacherProfile || {};

  const { courses } = CurriculumStore || {};
  const courseNames = courses
    .filter(c => c?.curriculum?.title !== "Extras" || c?.name === "Let's Play Recorder")
    .filter(removeCoursesUserIsNotAuthorizedToTeach(teacherProfile))
    .map(c => c?.curriculum?.title + ": " + c.name)
    .sort();

  const existingLocations = currentLocations?.map(l => `${l.name} (${l.line1})`);

  const locations = [...["+ Add New"], ...(existingLocations || [])];
  const [loading, setLoading] = useState(false);

  const [classDetails, setClassDetails] = useState({});
  const { name, course, locationName, codeRequired } = classDetails || {};
  const { newLocation, newLocationName, address, city, state, zip } = classDetails || {};
  const { dayOfWeek, startDate, capacity, sessionTime, registrationFee, tuitionFee } = classDetails || {};

  const [selectedCurriculum, selectedCourse] = course?.split(": ") || [];
  const { id: courseId } =
    courses?.find(c => c?.name === selectedCourse && c?.curriculum?.title === selectedCurriculum) || {};
  const newOrContinuingClassLabel = course ? (RETURNING_COURSES?.includes(courseId) ? "continuing" : "new") : null;
  const formattedStartDate = startDate && format(startDate, "yyyy-MM-dd");
  const { shippingWindow, classWindow } =
    course && startDate ? getApplicableShippingDateWindow({ startDate: formattedStartDate, courseId }) : {};
  const applicableShippingDate = shippingDates?.[shippingWindow]
    ? format(parseISO(shippingDates?.[shippingWindow]), "yyyy-MM-dd")
    : null;

  const validated =
    !!name &&
    name !== "" &&
    course &&
    (locationName || (newLocation && newLocationName && address && city && state && zip)) &&
    dayOfWeek &&
    startDate &&
    applicableShippingDate &&
    capacity &&
    sessionTime;

  const updateClassDetailsField =
    (fieldName, { raw, checkbox } = {}) =>
    val => {
      if (checkbox) {
        const [value] = val || [];
        setClassDetails(prev => ({ ...prev, [fieldName]: value }));
      } else {
        const value = raw ? val : val?.target?.value;
        setClassDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };
  const updateName = updateClassDetailsField("name");
  const updateCourse = updateClassDetailsField("course", { raw: true });
  const updateLocationName = updateClassDetailsField("locationName", { raw: true });

  const updateNewLocation = updateClassDetailsField("newLocation", { raw: true });
  const updateNewLocationName = updateClassDetailsField("newLocationName");
  const updateAddress = updateClassDetailsField("address");
  const updateCity = updateClassDetailsField("city");
  const updateState = updateClassDetailsField("state", { raw: true });
  const updateZip = updateClassDetailsField("zip");

  const updateCapacity = updateClassDetailsField("capacity");
  const updateCodeRequired = updateClassDetailsField("codeRequired", { checkbox: true });
  const updateRegistrationFee = updateClassDetailsField("registrationFee");
  const updateTuitionFee = updateClassDetailsField("tuitionFee");

  const updateDayOfWeek = updateClassDetailsField("dayOfWeek", { raw: true });
  const updateStartDate = updateClassDetailsField("startDate", { raw: true });
  const updateSessionTime = updateClassDetailsField("sessionTime", { raw: true });

  const saveClass = async () => {
    const { course, locationName, startDate, newLocation, newLocationName, address, city, state, zip, ...classParams } =
      classDetails || {};

    let formattedLocation;
    if (newLocation) {
      formattedLocation = {
        zipCode: zip,
        postalCode: zip,
        locality: city,
        countryCode: "USA",
        name: locationName || newLocationName,
        region: state,
        line1: address,
        newLocation
      };
    } else {
      const existingLocationName = locationName.split(" (")[0];
      const existingLocation = currentLocations?.find(l => l.name === existingLocationName) || {};
      formattedLocation = existingLocation;
    }

    const [selectedCurriculum, selectedCourse] = course?.split(": ") || [];
    const { id: courseId } =
      courses?.find(c => c?.name === selectedCourse && c?.curriculum?.title === selectedCurriculum) || {};

    const newClassParams = {
      ...classParams,
      courseId,
      startDate: format(startDate, "yyyy-MM-dd"),
      shippingDate: applicableShippingDate,
      sessionTime: format(sessionTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      location: formattedLocation
    };
    setLoading(true);
    await ClassesStore.addClass(newClassParams);
    setLoading(false);
    close();
  };

  if (locationName === "+ Add New") {
    updateLocationName("");
    updateNewLocation(true);
  }

  const locationInput = newLocation ? (
    <NewLocation
      locationName={newLocationName}
      address={address}
      city={city}
      updateLocationName={updateNewLocationName}
      updateAddress={updateAddress}
      updateCity={updateCity}
      setNewLocation={updateNewLocation}
      state={state}
      zip={zip}
      updateZip={updateZip}
      updateState={updateState}
    />
  ) : (
    <ExistingLocation locations={locations} location={locationName} setLocation={updateLocationName} />
  );

  let shippingDateSection;
  if (!!course && !!startDate) {
    const applicableShippingDateLabel = shippingDates?.[shippingWindow]
      ? format(parseISO(shippingDates?.[shippingWindow]), "MMM do, yyyy")
      : null;
    const pastShippingDate = formattedStartDate <= applicableShippingDate;
    const classWindowLabel = WINDOW_LABELS?.[classWindow];
    const shippingWindowLabel = WINDOW_LABELS?.[shippingWindow];

    let headerLabel = `Shipping Date: ${applicableShippingDateLabel}`;
    let explanation = (
      <div className="shipping-date-explanation">
        Because this is a {newOrContinuingClassLabel} class starting during the {classWindowLabel} semester, your
        materials will be shipped on your configured {shippingWindowLabel} window shipping date. If you have a good
        reason this date needs to be changed, reach out to us at{" "}
        <a href="mailto:office@letsplaymusicsite.com">office@letsplaymusicsite.com</a> and we can help you with that.
      </div>
    );

    if (pastShippingDate) {
      headerLabel = "You're trying to start a class before your shipping date.";
      explanation = (
        <div className="shipping-date-explanation">
          Because this is a {newOrContinuingClassLabel} class starting during the {classWindowLabel} semester, your
          materials wouldn't ship until {applicableShippingDateLabel} (your previously configured {shippingWindowLabel}{" "}
          window shipping date). If this is not a mistake and you still need to have materials shipped, contact us at{" "}
          <a href="mailto:office@letsplaymusicsite.com">office@letsplaymusicsite.com</a> and we can help you with that.
        </div>
      );
    }

    shippingDateSection = !!course ? (
      <>
        <div className="section-header shipping-date">{headerLabel}</div>
        <div className="section-container shipping-date">{explanation}</div>
      </>
    ) : null;
  }

  const cancelButtonStyles = loading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = validated
    ? loading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = loading ? <Spinner /> : "Add Class";

  return (
    <div className="add-class-modal">
      <div className="content">
        <div className="title">Create New Class</div>
        <div className="columns">
          <div className="col">
            <div className="section-header">Class Details</div>
            <div className="section-container">
              <div className="section-title">Class Name</div>
              <Input placeholder="Class Name" value={name} onChange={updateName} />
            </div>
            <div className="section-container">
              <div className="section-title">Course Type</div>
              <Select options={courseNames} value={course} onChange={updateCourse} />
            </div>
            {locationInput}
            <div className="section-header">Enrollment Details</div>
            <div className="section-container double">
              <div className="small">
                <div className="section-title">Max Students</div>
                <Input
                  icon={FiHash}
                  type="number"
                  value={capacity}
                  placeholder="Max Students"
                  min="1"
                  onChange={updateCapacity}
                />
              </div>
              <div>
                <div className="section-title">&nbsp;</div>
                <div className="checkbox-wrapper">
                  <Checkbox
                    selected={codeRequired}
                    onChange={updateCodeRequired}
                    value={"Enrollment ID Code Required?"}
                  />
                </div>
              </div>
            </div>
            <div className="section-container double">
              <div>
                <div className="section-title">Registration Fee</div>
                <Input
                  placeholder="Registration Fee"
                  value={registrationFee}
                  onChange={updateRegistrationFee}
                  icon={FiDollarSign}
                  type={"number"}
                  min={0}
                  step={1}
                />
              </div>
              <div>
                <div className="section-title">Tuition Fee</div>
                <Input
                  placeholder="Tuition Fee"
                  value={tuitionFee}
                  onChange={updateTuitionFee}
                  icon={FiDollarSign}
                  type={"number"}
                  min={0}
                  step={1}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="section-header">Schedule Details</div>
            <div className="section-container">
              <div>
                <div className="section-title">Start Date</div>
                <DatePicker
                  placeholder="Start Date"
                  value={startDate}
                  minDate={new Date()}
                  onChange={updateStartDate}
                />
              </div>
            </div>
            <div className="section-container double">
              <div>
                <div className="section-title">Day of Week</div>
                <Select options={weekdays} value={dayOfWeek} onChange={updateDayOfWeek} />
              </div>
              <div>
                <div className="section-title">Session Time</div>
                <DatePicker
                  placeholder="Session Time"
                  value={sessionTime}
                  onChange={time => updateSessionTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  strictParsing
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
            </div>
            {shippingDateSection}
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={saveClass} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddClassModal);
