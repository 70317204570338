import React, { useState } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";
import { FiSearch } from "react-icons/fi";
import { downloadURLContents } from "../../utils";
import UserStore from "../../stores/UserStore";
import useModal from "../../hooks/useModal";
import { Spinner, Input } from "../-common";
import UploadFileModalBody from "./UploadFileModal";
import "./Files.scss";

const FileRow = ({ filename, assetURL, type, created }) => {
  const downloadFile = () => downloadURLContents(assetURL);

  const uploadDateLabel = format(parseISO(created), "MMM do, ''yy");

  return (
    <tr>
      <td>
        <div className="cell-contents">{filename}</div>
      </td>
      <td>
        <div className="cell-contents">{type}</div>
      </td>
      <td>
        <div className="cell-contents">{uploadDateLabel}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={downloadFile}>
            Download
          </div>
        </div>
      </td>
    </tr>
  );
};

const Files = observer(() => {
  const { loading: loadingUser, loadingFiles, files } = UserStore || {};

  const { Modal: AddFileModal, openModal: openAddFileModal } = useModal(
    UploadFileModalBody,
    {},
    { portalElementId: "add-file-modal", big: true }
  );

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target?.value);

  if (loadingUser || loadingFiles) {
    return (
      <div className="files files-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const relevantFiles = files?.filter(f => {
    if (!searchText) return true;
    return f?.filename?.toLowerCase()?.includes(searchText?.toLowerCase());
  });
  const fileRows = relevantFiles?.map(f => <FileRow {...f} key={f?.id} />);

  return (
    <div className="files">
      <div className="title-row">
        <div className="title">Uploads & Certs</div>
      </div>
      <div className="upper">
        {/* <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
          <div className="info-row">
            <div className="info-title">Some Stat</div>
            <div className="info-value">value</div>
          </div>
        </div> */}
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <span onClick={openAddFileModal}>Upload or Verify File</span>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-search-row">
        <div className="header">Files</div>
        <div className="search-files">
          <Input icon={FiSearch} placeholder="Search files..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      <div className="table-wrapper files-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Upload Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{fileRows}</tbody>
        </table>
      </div>
      <AddFileModal />
    </div>
  );
});

export default Files;
