import { makeAutoObservable } from "mobx";
import { Auth } from "aws-amplify";
import { request } from "../utils";
import { resetAmplitude, setAmplitudeUserId, trackAmplitudeEvent } from "../services/AmplitudeService";
import { EVENT_NAMES } from "../constants";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    this.checkForAuthenticatedUser();
  }

  authDetermined = false;
  authenticated = false;
  user = {};
  groups = [];

  loginAnalyticsEvents() {
    setAmplitudeUserId(this.id);
    trackAmplitudeEvent(EVENT_NAMES.LOGGED_IN);
  }

  get sub() {
    return this.user?.attributes?.sub;
  }

  get id() {
    return this.user?.attributes?.["custom:lpmId"];
  }

  get infusionsoftId() {
    return this.user?.attributes?.["custom:infusionsoftId"];
  }

  get email() {
    return this.user?.attributes?.["email"];
  }

  loading = false;

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const { "cognito:groups": groups } = session?.accessToken?.payload || {};

      if (user) {
        this.authenticated = true;
        this.user = user;
        this.groups = groups;
        this.loginAnalyticsEvents();
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;

    // this.authenticated = true;
    // this.authDetermined = true;
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      await Auth.signIn(username || "-", password || "-");
      let user = await Auth.currentAuthenticatedUser();
      let session = await Auth.currentSession();
      const { "cognito:groups": groups } = session?.accessToken?.payload || {};

      if (!user?.attributes?.["custom:lpmId"]) {
        try {
          await request.post("/users/me/sync");
          await Auth.signIn(username || "-", password || "-");
          user = await Auth.currentAuthenticatedUser();
          session = await Auth.currentSession();
        } catch {}
      }

      this.user = user;
      this.groups = groups;
      this.authenticated = true;
      this.loading = false;
      this.loginAnalyticsEvents();
      return { success: true };
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async signUp({ email, password, firstName, lastName, isConsultant }) {
    this.loading = true;

    let success, error;
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          "custom:accountType": isConsultant ? "consultant" : "company"
        }
      });
      success = true;
    } catch (err) {
      console.warn(err);
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resendVerificationCode(email) {
    let success, error;

    this.loading = true;
    try {
      await Auth.resendSignUp(email);
      success = true;
    } catch (err) {
      error = err.message;
      success = false;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(username, password, code) {
    this.loading = true;
    try {
      await Auth.confirmSignUp(username, code, { forceAliasCreation: true });
      await this.signIn(username, password);
      return { success: true };
    } catch (err) {
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async sendResetPasswordCode(email) {
    let success, error;

    this.loading = true;
    try {
      await Auth.forgotPassword(email);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resetPassword(username, code, newPassword) {
    this.loading = true;
    let success, error;

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
      trackAmplitudeEvent(EVENT_NAMES.LOGGED_OUT);
      resetAmplitude();
    } catch (err) {
      console.warn(err);
    }
  }
}

export default new AuthStore();
