// eslint-disable-next-line
String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export { default as downloadURLContents } from "./downloadURLContents";
export { default as getDataURLFromFile } from "./getDataURLFromFile";
export { default as request } from "./request";
