import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiCalendar, FiUser } from "react-icons/fi";
import UserStore from "../../../stores/UserStore";
import { Button, DatePicker, Input, Select, Spinner } from "../../-common";
import "./AddChildModal.scss";

const RequestSentContent = ({ firstName, reset, close }) => (
  <>
    <div className="content">
      <div className="title">Add a New Child</div>
      <div className="message-wrapper">
        <div className="message">Thanks! We've got {firstName} created in our system.</div>
      </div>
    </div>
    <div className="bottom-buttons">
      <div className="left-buttons">
        <Button type="secondary" action={close}>
          CLOSE
        </Button>
      </div>
      <div className="right-buttons">
        <Button type="primary" action={reset}>
          ADD ANOTHER CHILD
        </Button>
      </div>
    </div>
  </>
);

function AddChildModal({ close }) {
  const [firstName, setFirstName] = useState("");
  const updateFirstName = ({ target }) => setFirstName(target.value);

  const [lastName, setLastName] = useState("");
  const updateLastName = ({ target }) => setLastName(target.value);

  // const [gender, setGender] = useState("");
  const [gender, setGender] = useState();

  const [birthday, setBirthday] = useState();

  const [loading, setLoading] = useState(false);
  const [childCreated, setChildCreated] = useState(false);

  const reset = () => {
    setFirstName("");
    setLastName("");
    setGender();
    setBirthday(null);
    setLoading(false);
    setChildCreated(false);
  };

  const createEnabled = !!firstName && !!lastName && !!gender && !!birthday;
  const createChild = async () => {
    if (createEnabled) {
      setLoading(true);
      const success = await UserStore?.createChild({ firstName, lastName, gender, birthday: birthday?.toISOString() });
      if (success) setChildCreated(true);
      setLoading(false);
    }
  };

  if (childCreated) {
    return (
      <div className="add-student-modal small">
        <RequestSentContent {...{ firstName, reset, close }} />
      </div>
    );
  }

  const sendButtonContent = loading ? <Spinner /> : "Add Child";

  return (
    <div className="add-child-modal">
      <div className="content">
        <div className="title">Add a New Child</div>
        <div className="input-wrapper">
          <div className="inputs-row-title">Name</div>
          <div className="inputs-row">
            <Input type="text" placeholder="First" value={firstName} onChange={updateFirstName} />
            <Input type="text" placeholder="Last" value={lastName} onChange={updateLastName} />
          </div>
        </div>
        <div className="inputs-row">
          <div className="inputs-row-section">
            <div className="inputs-row-title">Gender</div>
            <Select
              icon={FiUser}
              placeholder="Select..."
              options={["Female", "Male"]}
              value={gender}
              onChange={setGender}
            />
          </div>
          <div className="inputs-row-section">
            <div className="inputs-row-title">Birthday</div>
            <DatePicker icon={FiCalendar} placeholderText="ex. 01/01/2016" value={birthday} onChange={setBirthday} />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={createChild}
            disabled={!createEnabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddChildModal);
