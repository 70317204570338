import { makeAutoObservable, autorun } from "mobx";
// import uniqBy from "lodash.uniqby";
import { ENV } from "../config";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import UserStore from "./UserStore";
import CurriculumStore from "./CurriculumStore";

const parseTestimonials = testimonialsString => {
  const splitIntoBlurbs = testimonialsString?.split(/\r\n/)?.filter(s => s && !s?.match(/^(\r\n)+$/));

  let withTrailingAttributionsCleanedUp = [];
  let current;
  for (let blurb of splitIntoBlurbs?.reverse()) {
    if (blurb?.trim()?.match(/^-/)) {
      current = blurb;
    } else {
      if (current) {
        current = blurb + " " + current;
        withTrailingAttributionsCleanedUp = withTrailingAttributionsCleanedUp.concat(current);
        current = null;
      } else {
        withTrailingAttributionsCleanedUp = withTrailingAttributionsCleanedUp.concat(blurb);
      }
    }
  }

  const withSplitAttributions = withTrailingAttributionsCleanedUp?.map(blurb => {
    const [attribution, ...rest] = blurb?.split(" -")?.reverse();
    return { attribution, text: rest?.join(" -") };
  });

  return withSplitAttributions?.reverse();
};

const augmentTeacherItem = teacher => {
  let website = teacher?.website;
  if (website && !website?.match(/^https?:\/\//)) website = "https://" + website;

  let blog = teacher?.blog;
  if (blog && !blog?.match(/^https?:\/\//)) blog = "https://" + blog;
  return {
    ...teacher,
    profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${teacher.id}?m=${teacher.modified}`,
    classes: teacher?.classes?.map(c => ({
      ...c,
      course: CurriculumStore?.coursesById?.[c?.courseId],
      seatsAvailable: Math.max(0, c?.capacity - c?.stats?.studentsEnrolled || 0)
    })),
    website,
    blog,
    testimonials: teacher?.testimonials ? parseTestimonials(teacher.testimonials) : null
  };
};

class TeachersStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub && UserStore?.user?.parent) {
        this.fetchTeachers();
      } else this.clear();
    });
  }

  loading = false;

  rawTeachers = [];

  get teachers() {
    return this.rawTeachers?.map(augmentTeacherItem);
  }

  async fetchTeachers() {
    this.loading = true;
    try {
      const teachers = await request.get(`/teachers`);
      this.rawTeachers = teachers;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  clear() {
    this.rawTeachers = [];
  }
}

export default new TeachersStore();
