import { makeAutoObservable, autorun } from "mobx";
import mapValues from "lodash.mapvalues";
import { parsePhoneNumber } from "libphonenumber-js";
import { ENV } from "../config";
import { downloadURLContents, request } from "../utils";
import AuthStore from "./AuthStore";
import UserStore from "./UserStore";
import CurriculumStore from "./CurriculumStore";

const studentCSVHeaderRow = [
  "Registration Date",
  "Student Name",
  "Student Birthday",
  "Parent Name",
  "Email Address",
  "Phone Number"
];

const sanitizeCSVField = field => field?.replace(/"/, '""')?.replace(/\\n/, " ");

class ClassesStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub && UserStore?.user?.teacher) {
        this.fetchClasses();
      } else this.clear();
    });
  }

  loading = false;

  rawClasses = null;

  get allClasses() {
    return (
      this.rawClasses?.map(c => ({
        ...c,
        course: CurriculumStore?.coursesById?.[c?.courseId],
        courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${c?.courseId}?m=${c?.modified}`,
        openSeats: Math.max(c?.capacity - c?.stats?.studentsEnrolled, 0),
        name: c?.name || "(no class name found)"
      })) || []
    );
  }

  get classesByClassId() {
    return Object.fromEntries(this.allClasses?.map(c => [c?.id || c?.classId, c]));
  }

  get currentAndUpcomingClasses() {
    return this.allClasses.filter(c => c?.status === "current" || c?.status === "ready");
  }

  get currentClasses() {
    return this.allClasses.filter(c => c?.status === "ready");
  }

  get archivedClasses() {
    return this.allClasses.filter(c => c?.status === "ended");
  }

  get classStatusOptions() {
    return Array.from(new Set(this.allClasses?.map(c => c?.status)))
      ?.sort((a, b) => {
        if (a === "ready") return -1;
        if (b === "ready") return 1;
        return a > b ? 1 : -1;
      })
      ?.map(status => {
        if (status === "ready") return "Upcoming";
        else return status.toProperCase();
      })
      ?.concat("All");
  }

  loadingStudents = {};

  rawStudentsByClassId = {};

  get studentsByClassId() {
    return mapValues(this.rawStudentsByClassId, students => {
      return students
        ?.map(s => ({
          ...s,
          firstName: s?.firstName?.toProperCase(),
          lastName: s?.lastName?.toProperCase(),
          profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${s?.id}?m=${s?.modified}`,
          parent: {
            ...(s?.parent || {}),
            profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${s?.parent?.id}?m=${s?.parent?.modified}`
          }
        }))
        ?.sort((a, b) => {
          if (a?.lastName === b?.lastName) return a?.firstName > b?.firstName ? 1 : -1;
          return (a?.lastName || "zzzz") > (b?.lastName || "zzzz") ? 1 : -1;
        });
    });
  }

  async fetchClasses(isRefresh) {
    if (!isRefresh && this.rawClasses != null) return;

    this.loading = true;
    try {
      const classes = await request.get(`/classes`);
      this.rawClasses = classes;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async fetchStudentsForClass(classId) {
    this.loadingStudents = { ...this.loadingStudents, [classId]: true };
    try {
      const students = await request.get(`/classes/${classId}/students`);
      this.rawStudentsByClassId = { ...this.rawStudentsByClassId, [classId]: students };
      this.loadingStudents = { ...this.loadingStudents, [classId]: false };
      return students;
    } catch (err) {
      console.warn(err);
      this.loadingStudents = { ...this.loadingStudents, [classId]: false };
    }
  }

  async downloadRosterForClass(classId) {
    try {
      const className = this.classesByClassId?.[classId]?.name;
      const students = this.rawStudentsByClassId[classId];
      const studentRows = students
        ?.sort((a, b) => {
          const aLastName = a?.lastName || a?.parent?.lastName;
          const bLastName = b?.lastName || b?.parent?.lastName;
          if (aLastName === bLastName) return a?.firstName < b?.firstName ? -1 : 1;
          else return aLastName < bLastName ? -1 : 1;
        })
        ?.map(({ firstName, lastName, birthday, parent, registrationDate }) => {
          let phone;
          if (parent && parent?.phone && parsePhoneNumber(parent?.phone, "US").isValid()) {
            phone = parsePhoneNumber(parent.phone, "US").format("NATIONAL");
          }
          return {
            "Registration Date": registrationDate?.slice(0, 10),
            "Student Name": `${firstName} ${lastName || parent?.lastName}`,
            "Student Birthday": birthday?.slice(0, 10),
            "Parent Name": `${parent?.firstName} ${parent?.lastName}`,
            "Email Address": parent?.email,
            "Phone Number": phone
          };
        });
      const rosterRows = studentRows
        ?.map(row => '"' + studentCSVHeaderRow?.map(header => sanitizeCSVField(row?.[header]))?.join('","') + '"')
        ?.join("\n");
      const rosterCSV = '"' + studentCSVHeaderRow?.join('","') + '"\n' + rosterRows;
      const href = URL.createObjectURL(new Blob([rosterCSV]));
      downloadURLContents(href, className || "roster", ".csv");
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async downloadFullCurrentRoster() {
    try {
      const rosterCSV = await request.get(`/classes/roster`, { responseType: "blob" });
      const href = URL.createObjectURL(new Blob([rosterCSV]));
      downloadURLContents(href, "Full Roster", ".csv");
    } catch (err) {
      console.warn(err);
    }
  }

  async addClass(classParams) {
    try {
      const newClass = await request.post(`/classes`, { body: classParams });
      this.rawClasses = this.rawClasses?.concat(newClass);

      if (classParams?.location?.newLocation) await UserStore.fetchUser();

      return newClass;
    } catch (err) {
      console.warn(err);
    }
  }

  async editClass(classId, updateParams) {
    try {
      const updatedClass = await request.put(`/classes/${classId}`, { body: updateParams });
      this.rawClasses = this.rawClasses?.map(rc => (rc?.id === classId || rc?.classId === classId ? updatedClass : rc));
      return updatedClass;
    } catch (err) {
      console.warn(err);
    }
  }

  async deleteClass(classId) {
    const currentClasses = this.rawClasses.slice();
    try {
      await request.delete(`/classes/${classId}`);
      this.rawClasses = this.rawClasses?.filter(c => c?.id !== classId && c?.classId !== classId) || [];
      return true;
    } catch (err) {
      this.rawClasses = currentClasses;
      console.warn(err);
      return false;
    }
  }

  async reassignStudents(reassignParams) {
    try {
      await request.post(`/classes/reassign`, {
        body: { ...reassignParams, teacherId: UserStore?.user?.id }
      });
      this.fetchStudentsForClass(reassignParams?.oldClassId);
      this.fetchStudentsForClass(reassignParams?.newClassId);
      this.fetchClasses(true);
    } catch (err) {
      console.warn(err);
    }
  }

  clear() {
    this.rawClasses = null;
  }
}

export default new ClassesStore();
