import React, { useState } from "react";
import { observer } from "mobx-react";
import sendFeedback from "../../../services/FeedbackService";
import { Button, Spinner, TextArea } from "../../-common";
import "./AddFeedbackModal.scss";

const RequestSentContent = ({ reset, close }) => (
  <>
    <div className="content">
      <div className="title">What can we help with?</div>
      <div className="message-wrapper">
        <div className="message">Thank you for your feedback!</div>
        <div className="message"> We'll review your request and respond to your email, as needed.</div>
      </div>
    </div>
    <div className="bottom-buttons">
      <div className="left-buttons">
        <Button type="secondary" action={close}>
          CLOSE
        </Button>
      </div>
      <div className="right-buttons">
        <Button type="primary" action={reset}>
          MORE HELP
        </Button>
      </div>
    </div>
  </>
);

function AddFeedbackModal({ close }) {
  const [feedback, setFeedback] = useState("");
  const updateFeedback = ({ target }) => setFeedback(target.value);

  const [loading, setLoading] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const reset = () => {
    setFeedback("");
    setLoading(false);
    setFeedbackSubmitted(false);
  };

  const submitEnabled = !!feedback;

  const submitFeedback = async () => {
    if (submitEnabled) {
      setLoading(true);
      const success = await sendFeedback(feedback);
      if (success) setFeedbackSubmitted(true);
      setLoading(false);
    }
  };

  if (feedbackSubmitted) {
    return (
      <div className="add-feedback-modal small">
        <RequestSentContent {...{ reset, close }} />
      </div>
    );
  }

  const sendButtonContent = loading ? <Spinner /> : "Get Help";

  return (
    <div className="add-feedback-modal">
      <div className="content">
        <div className="title">What can we help with?</div>
        <div className="input-wrapper">
          <div className="inputs-row-title">Comment</div>
          <div className="inputs-row">
            <TextArea
              placeholder={"Please describe your issue with as much detail as possible..."}
              height={350}
              value={feedback}
              onChange={updateFeedback}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={submitFeedback}
            disabled={!submitEnabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddFeedbackModal);
