import { makeAutoObservable, autorun } from "mobx";
import { ENV } from "../config";
import { request } from "../utils";
import AuthStore from "./AuthStore";

class CurriculumStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) this.init();
      else this.clear();
    });
  }

  loading = false;

  rawCurricula = [];
  get curricula() {
    return this.rawCurricula
      ?.slice()
      ?.sort((a, b) => a?.order - b?.order)
      ?.map(curriculum => {
        const coursesForCurriculum = this.courses
          ?.filter(course => course?.curriculum?.id === curriculum?.id)
          ?.sort((a, b) => a?.order - b?.order);
        const albums = coursesForCurriculum
          ?.map(({ albums }) => albums)
          ?.flat()
          ?.filter(Boolean);
        const courses = this.coursesByCurriculumId[curriculum?.id];
        return { ...curriculum, courses, albums };
      });
  }

  get curriculaById() {
    return this.curricula?.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  rawCourses = [];
  get courses() {
    return this.rawCourses.map(course => ({
      ...course,
      courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${course?.id}?m=${course?.modified}`,
      albums: course?.albums?.map(albumId => this.albumsById?.[albumId])?.sort((a, b) => a?.order - b?.order)
    }));
  }

  get coursesById() {
    return this.courses.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  get coursesByCurriculumId() {
    const coursesGroupedByCurriculum = this.courses.reduce((acc, next) => {
      if (acc[next?.curriculum?.id]) acc[next.curriculum.id] = acc[next.curriculum.id].concat(next);
      else acc[next.curriculum.id] = [next];
      return acc;
    }, {});
    const curriculumCourseEntriesWithSortedCourses = Object.entries(
      coursesGroupedByCurriculum
    )?.map(([curriculumId, courses]) => [curriculumId, courses?.sort((a, b) => a?.order - b?.order)]);
    return Object.fromEntries(curriculumCourseEntriesWithSortedCourses);
  }

  rawAlbums = [];
  loadingAlbumDetails = {};
  rawAlbumDetails = {};

  get albumDetails() {
    const betterAlbumDetailsEntries = Object.entries(this.rawAlbumDetails).map(([albumId, album]) => [
      albumId,
      {
        ...album,
        tracks: album?.tracks
          ?.map(t => ({
            ...t,
            url: `https://${ENV}-lpm-albums.b-cdn.net/${album.id}/${t.id}?m=${t.modified}`
          }))
          ?.sort((a, b) => a?.order - b?.order),
        playlists: album?.playlists?.slice()?.sort((a, b) => a?.sortOrder - b?.sortOrder)
      }
    ]);
    return Object.fromEntries(betterAlbumDetailsEntries);
  }

  get albums() {
    return this.rawAlbums?.map(album => {
      const albumDetails = this.albumDetails[album?.id] ? this.albumDetails[album.id] : {};
      const modified = album?.modified > albumDetails?.modified ? album?.modified : albumDetails?.modified;
      return {
        ...album,
        ...albumDetails,
        modified,
        artwork: `https://${ENV}-lpm-assets.b-cdn.net/artwork/${album.id}?m=${modified}`,
        playlists: albumDetails?.playlists
          ?.filter(playlist => playlist?.order != null)
          ?.sort((a, b) => a?.order - b?.order)
          ?.map(playlist => ({
            ...playlist,
            tracks: playlist?.tracks?.map(trackId => albumDetails?.tracks?.find(t => trackId === t?.id))
          }))
      };
    });
  }

  get albumsById() {
    return this.albums.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  inFlightFetchCurriculaRequest;
  async fetchCurricula() {
    try {
      if (!this.inFlightFetchCurriculaRequest) this.inFlightFetchCurriculaRequest = request.get(`/curricula`);
      const curricula = await this.inFlightFetchCurriculaRequest;
      this.inFlightFetchCurriculaRequest = null;
      this.rawCurricula = curricula;
    } catch (err) {
      console.warn(err);
    }
  }

  inFlightFetchCoursesRequest;
  async fetchCourses() {
    try {
      if (!this.inFlightFetchCoursesRequest) this.inFlightFetchCoursesRequest = request.get(`/courses`);
      const courses = await this.inFlightFetchCoursesRequest;
      this.inFlightFetchCoursesRequest = null;
      this.rawCourses = courses;
    } catch (err) {
      console.warn(err);
    }
  }

  inFlightFetchAlbumListRequest;
  async fetchAlbumList() {
    try {
      if (!this.inFlightFetchAlbumListRequest) this.inFlightFetchAlbumListRequest = request.get(`/albums`);
      const albums = await this.inFlightFetchAlbumListRequest;
      this.inFlightFetchAlbumListRequest = null;
      this.rawAlbums = albums;
    } catch (err) {
      console.warn(err);
    }
  }

  async fetchAlbumDetails(albumId) {
    this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: true };
    try {
      const album = await request.get(`/albums/${albumId}`);
      this.rawAlbumDetails = { ...this.rawAlbumDetails, [albumId]: album };
      this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: false };
      return album;
    } catch (err) {
      console.warn(err);
      this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: false };
    }
  }

  async init() {
    this.loading = true;
    try {
      await Promise.all([this.fetchCurricula(), this.fetchCourses(), this.fetchAlbumList()]);
    } catch {
      this.loading = false;
    }
  }

  clear() {
    this.loading = false;
    this.rawCurricula = [];
    this.rawCourses = [];
    this.rawAlbums = [];
    this.loadingAlbumDetails = {};
    this.rawAlbumDetails = {};
  }
}

export default new CurriculumStore();
