import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format, parseISO } from "date-fns";
import "./ClassRow.scss";

const ClassRow = observer(
  ({ id, classId: fallbackClassId, courseLogo, name, sessionTime, period, shippingDate, stats, openSeats }) => {
    const classId = id || fallbackClassId;

    const { parentsEnrolled = 0, studentsEnrolled = 0 } = stats || {};

    const [courseLogoError, setCourseLogoError] = useState(false);
    const markCourseLogoError = () => setCourseLogoError(true);

    const goToClassDetails = () => navigate(`/classes/${classId}`);

    const courseLogoComponent = courseLogoError ? (
      <div className="course-logo placeholder"></div>
    ) : (
      <img className="course-logo" src={courseLogo} alt="Course logo" onError={markCourseLogoError} />
    );

    const validShippingDate = shippingDate && shippingDate !== "0000-00-00";
    const formattedShippingDate = validShippingDate ? format(parseISO(shippingDate), "MMM do yyyy") : null;
    const shippingDateLabel = validShippingDate ? `(materials ship on ${formattedShippingDate})` : "";

    const openSeatsLabel = openSeats > 0 ? `${openSeats} open seat${openSeats !== 1 ? "s" : ""}` : "(class is full)";

    return (
      <tr>
        <td>
          <div className="cell-contents flex-center">
            {courseLogoComponent}
            <div>
              <div>{name}</div>
              <div className="ship-date">{sessionTime}</div>
            </div>
          </div>
        </td>
        <td>
          <div className="cell-contents">
            <div>{period}</div>
            <div className="ship-date">{shippingDateLabel}</div>
          </div>
        </td>
        <td>
          <div className="cell-contents">
            <div>
              {studentsEnrolled} student{studentsEnrolled !== 1 ? "s" : ""} with {parentsEnrolled} parent
              {parentsEnrolled !== 1 ? "s" : ""}
            </div>
            <div className="ship-date">{openSeatsLabel}</div>
          </div>
        </td>
        <td>
          <div className="cell-contents">
            <div className="action" onClick={goToClassDetails}>
              View Details
            </div>
          </div>
        </td>
      </tr>
    );
  }
);

export default ClassRow;
