import React from "react";
import { observer } from "mobx-react";
import { FiCheck } from "react-icons/fi";
import useModal from "../../../../../hooks/useModal";
import MaterialsStore from "../../../../../stores/MaterialsStore";
import ReassignStudentsModalBody from "./ReassignStudentsModal";
import ParentCell from "./ParentCell";
import StudentItem from "./StudentItem";
import "./RosterRow.scss";

const RosterRow = observer(({ teacher, class: classItem, parent, materials, students }) => {
  const studentItems = students?.map(student => <StudentItem student={student} key={student?.id} />);

  const { Modal: ReassignStudentsModal, openModal: openReassignStudentsModal } = useModal(
    ReassignStudentsModalBody,
    { teacher, class: classItem, parent, students, materials },
    { portalElementId: "reassign-students-modal" }
  );

  const curatedMaterials = materials?.reduce((acc, next) => {
    acc[next.productId] = {
      ...(acc[next.productId] || {}),
      ...MaterialsStore?.materialsByProductId?.[next?.productId],
      ...next,
      quantity: Number(acc[next.productId]?.quantity || 0) + 1,
      fulfilled:
        acc?.[next?.productId]?.fulfilled != null
          ? acc?.[next?.productId]?.fulfilled && ["processed", "pending return"]?.includes(next?.status)
          : ["processed", "pending return"]?.includes(next?.status)
    };
    return acc;
  }, {});
  const materialInfoPairs = Object.entries(curatedMaterials)
    ?.map(([productId, info]) => [MaterialsStore?.materialsByProductId?.[productId]?.name, info])
    ?.filter(([productName]) => !productName?.toLowerCase()?.includes("shipping"))
    ?.filter(([productName]) => !productName?.toLowerCase()?.includes("fee"))
    ?.filter(([productName]) => !productName?.toLowerCase()?.includes("tax"));

  const materialsList = materialInfoPairs?.map(([productName, { quantity, fulfilled }]) => {
    const fulfilledCheckmark = fulfilled ? <FiCheck className="fulfilled-checkmark" /> : null;
    return (
      <div className="product-container" key={productName}>
        {fulfilledCheckmark}
        <div className="product-name">{productName}</div>
        <div>x</div>
        <div className="product-quantity">{quantity}</div>
      </div>
    );
  });

  const reassignStudentActionLabel = `Reassign Student${students?.length > 1 ? "(s)" : ""}`;

  return (
    <tr className="roster-row">
      <td>
        <ParentCell {...parent} />
      </td>
      <td>{materialsList}</td>
      <td>
        <div className="cell-contents students">{studentItems}</div>
      </td>
      <td>
        <div className="cell-contents">
          <a href={`mailto:${parent?.email}`} className="action">
            Email Parent
          </a>
          <div className="action" onClick={openReassignStudentsModal}>
            {reassignStudentActionLabel}
          </div>
        </div>
      </td>
      <ReassignStudentsModal />
    </tr>
  );
});

export default RosterRow;
