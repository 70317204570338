import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiSearch } from "react-icons/fi";
import UserStore from "../../stores/UserStore";
import { Spinner, Input } from "../-common";
import "./Enrollments.scss";

const RegistrationRow = observer(({ courseLogoURL, class: classItem }) => {
  const { id: classId, sessionTime, period, teacher } = classItem || {};
  const { fullName: teacherName, pageURL: teacherPageURL } = teacher || {};
  const students = UserStore?.children?.filter(c => c?.registrations?.map(r => r?.classId)?.includes(classId));
  const studentNames = students?.map(s => s?.firstName)?.join(", ");

  const goToTeacherPage = () => window.open(teacherPageURL, "_blank");

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">
          <img src={courseLogoURL} className="course-logo" alt="Artwork for course" />
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div>
            {studentNames} with {teacherName}
          </div>
          <div>{sessionTime}</div>
          <div>({period})</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToTeacherPage}>
            Go To Teacher's Page
          </div>
        </div>
      </td>
    </tr>
  );
});

function Enrollments() {
  const { loading, user } = UserStore || {};
  const { registrations: enrollments } = user || {};

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target?.value);

  if (loading) {
    return (
      <div className="enrollments enrollments-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const totalEnrollments = enrollments?.length;
  const totalTeachers = [...new Set(enrollments?.map(e => e?.class?.teacher?.id))].length;

  const enrollmentRows = enrollments
    ?.filter(e => {
      if (!searchText) return true;

      const { id: classId, teacher, period } = e?.class || {};
      const students = UserStore?.children?.filter(c => c?.registrations?.map(r => r?.classId)?.includes(classId));
      const textToMatch = searchText?.toLowerCase();
      if (teacher?.fullName?.toLowerCase()?.includes(textToMatch)) return true;
      if (period?.toLowerCase()?.includes(textToMatch)) return true;
      for (let student of students) if (student?.fullName?.toLowerCase()?.includes(textToMatch)) return true;

      return false;
    })
    ?.map((registration, i) => <RegistrationRow {...registration} connections={user?.connections} key={i} />);
  const registrationsTableContent = enrollments?.length ? (
    <div className="table-wrapper enrollments-table">
      <table>
        <thead>
          <tr>
            <th>Course</th>
            <th>Info</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{enrollmentRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no enrollments)</div>
    </div>
  );

  return (
    <div className="enrollments">
      <div className="title-row">
        <div className="title">My Enrollments</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Total enrollments</div>
            <div className="info-value">{totalEnrollments}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Total teachers</div>
            <div className="info-value">{totalTeachers}</div>
          </div>
        </div>
      </div>
      <div className="search-row">
        <div className="header">Enrollments</div>
        <div className="search-enrollments">
          <Input icon={FiSearch} placeholder="Search enrollments..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {registrationsTableContent}
    </div>
  );
}

export default observer(Enrollments);
