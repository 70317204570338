export const FILE_CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

export const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const STATES = [
  "AB",
  "AL",
  "AK",
  "AZ",
  "AR",
  "BC",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "MB",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NB",
  "NL",
  "NS",
  "NU",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "ON",
  "OR",
  "PA",
  "PE",
  "QC",
  "RI",
  "SC",
  "SK",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "YT"
];

// AMPLITUDE EVENT TAXONOMY
// casing: lowercase
// syntax: verb then noun with spaces, adjectives as event properties
// verb tense: past-tense

export const EVENT_NAMES = {
  LOGGED_IN: "logged in",
  LOGGED_OUT: "logged out",
  VIEWED_PAGE: "viewed page"
};

export const EVENT_CATEGORIES = { SESSION: "session" };

export const EVENT_CATEGORY_MAPPING = {
  [EVENT_NAMES.LOGGED_IN]: EVENT_CATEGORIES.SESSION,
  [EVENT_NAMES.LOGGED_OUT]: EVENT_CATEGORIES.SESSION,
  [EVENT_NAMES.VIEWED_PAGE]: EVENT_CATEGORIES.SESSION
};
