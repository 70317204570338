import React, { useRef } from "react";
import "./ImageFileSelect.scss";

const defaultAccept = "image/png, image/jpeg";

const ImageFileSelect = ({ filePreviewURL, updateFile, accept }) => {
  const fileInputRef = useRef();

  const showFileSelector = () => fileInputRef?.current?.click();

  if (filePreviewURL) {
    return (
      <div className="image-file-select">
        <img
          className="file-upload-preview"
          src={filePreviewURL}
          alt="Preview for new upload"
          onClick={showFileSelector}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept={accept || defaultAccept}
          className="hidden-file-input"
          onChange={updateFile}
        />
      </div>
    );
  }

  return (
    <div className="image-file-select">
      <div className="file-upload-zone" onClick={showFileSelector}>
        <div className="file-upload-zone-inner">
          <div className="file-upload-zone-label">Click here to upload</div>
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept || defaultAccept}
        className="hidden-file-input"
        onChange={updateFile}
      />
    </div>
  );
};

export default ImageFileSelect;
