import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import ClassesStore from "../stores/ClassesStore";

export default function useClass(classId) {
  const { loading, allClasses, loadingStudents, studentsByClassId } = ClassesStore;

  const [classDetails, setClassDetails] = useState();
  useEffect(() => {
    const matchingClass = allClasses?.find(c => c?.id === classId || c?.classId === classId);
    if (matchingClass) {
      setClassDetails(toJS(matchingClass));
    } else {
      if (classId && !loading) {
        (async () => {
          await ClassesStore.fetchClasses();
        })();
      }
    }
  }, [loading, allClasses, classId]);

  const loadingStudentsForThisClass = loadingStudents?.[classId];
  const [students, setStudents] = useState();
  useEffect(() => {
    const matchingStudents = studentsByClassId?.[classId];
    if (matchingStudents) {
      setStudents(toJS(matchingStudents));
    } else {
      if (classId && !loadingStudentsForThisClass) {
        (async () => {
          const students = await ClassesStore.fetchStudentsForClass(classId);
          if (!students) navigate("/classes");
        })();
      }
    }
  }, [loadingStudentsForThisClass, studentsByClassId, classId]);

  return { ...classDetails, students, loading: loading || loadingStudentsForThisClass };
}
