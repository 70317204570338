import React, { useState } from "react";
import { FiUser } from "react-icons/fi";
import "./ParentCell.scss";

const ParentCell = ({ id, profilePicture, address, firstName, lastName, phone, email }) => {
  const [parentProfilePictureError, setParentProfilePictureError] = useState(false);
  const markParentProfilePictureError = () => setParentProfilePictureError(true);
  const parentProfilePictureComponent = parentProfilePictureError ? (
    <div className={`profile-picture placeholder`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img
      className="profile-picture"
      src={profilePicture || ""}
      alt="Parent profile"
      onError={markParentProfilePictureError}
    />
  );
  const parentAddressLine2 = address?.line2 ? <div className="aux">{address?.line2}</div> : null;
  const parentInfo = !!id ? (
    <>
      {parentProfilePictureComponent}
      <div>
        <div>
          {firstName} {lastName}
        </div>
        <div className="aux">{phone}</div>
        <div className="aux">{address?.line1}</div>
        {parentAddressLine2}
        <div className="aux">
          {address?.locality}, {address?.region} {address?.zipCode}
        </div>
        <div className="aux">{email}</div>
      </div>
    </>
  ) : (
    <div className="cell-contents flex-center">
      <div className="profile-picture empty" />
      <div className="aux">(no parent listed)</div>
    </div>
  );

  return <div className="cell-contents flex-center">{parentInfo}</div>;
};

export default ParentCell;
