import React from "react";
import { components as RSComponents } from "react-select";
import "./ClassOption.scss";

const ClassOption = props => {
  const { isSelected, value } = props || {};

  const { courseLogo, name, sessionTime, period, capacity, stats, isFirst } = value || {};
  const { studentsEnrolled } = stats || {};
  const seatsAvailable = Math.max(0, capacity - studentsEnrolled);

  const seatsAvailableLabel = seatsAvailable
    ? `${seatsAvailable} seat${seatsAvailable === 1 ? "" : "s"} available`
    : "(no seats available)";

  return (
    <div className={`class-option-wrapper${isFirst ? " first" : ""}`}>
      <RSComponents.Option {...props}>
        <div className={`class-option${isSelected ? " selected" : ""}`}>
          <img src={courseLogo} className="course-logo" alt="Course logo" />
          <div className="class-option-details">
            <div className="class-name">{name}</div>
            <div className="class-info">
              <div className="class-period">{period}</div>
              <div className="class-time">{sessionTime}</div>
              <div className="class-capacity">{seatsAvailableLabel}</div>
            </div>
          </div>
        </div>
      </RSComponents.Option>
    </div>
  );
};

export default ClassOption;
